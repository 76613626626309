import { Button, Paper, CircularProgress, Divider, Popper, Fade, Card, CardHeader, CardContent, Typography, CardActions, IconButton } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { _fetch } from "../../netReq";
import './styles.css';
import LocationFilter from '../../resources/locationFilter'
import LineIconLight from "../../react-lineicons-light";

export default function ScheduledPost() {

    const user = useSelector(state => state.userReducer)
    const [loading, setLoading] = useState(true);
    const [scheduledPost, setScheduledPost] = useState([]);
    const [locForNoti, setLocForNoti] = useState({});
    const history = useHistory();
    const [selectedLocations, setSelectedLocations] = useState([]);
    const search = useLocation().search;
    const name = new URLSearchParams(search).get('name');

    const [anchorEl, setAnchorEl] = useState(null);
    const [event, selectEvent] = useState({});

    const handleClick = (event, ev) => {
        selectEvent(event);
        setAnchorEl(ev.currentTarget);
    };

    useEffect(() => {
        if (Array.isArray(user.locations) && user.locations.length > 0)
            setSelectedLocations(name ? user.locations.filter(e => e.name === name) : user.locations);
    }, [name, setSelectedLocations, user.locations]);

    const getScheduledPost = useCallback(() => {

        let path = `${process.env.REACT_APP_API_URL}/scheduler?`;

        if (!Array.isArray(selectedLocations) || !selectedLocations.length)
            return setScheduledPost([]);

        path += `names=${JSON.stringify(selectedLocations.map((e) => e.name))}`;

        setLoading(true);

        _fetch(path)
            .then(res => {
                if (res.status === 200) {
                    setScheduledPost(res.response.result);
                }
                setLoading(false);
            })
            .catch(err => {
                process.env.NODE_ENV === 'development' && console.log(err);
                setLoading(false);
            })
    }, [selectedLocations]);

    useEffect(() => {

        if (user.locations) {
            let i = 0, name = {};
            while (i < user.locations.length) {
                name[user.locations[i].name] = `${user.locations[i].locationName} ${user.locations[i].nickName ? `(${user.locations[i].nickName})` : ''}`
                i += 1
            }
            setLocForNoti(name);
        }
    }, [user.locations, setLocForNoti]);

    useEffect(() => {
        getScheduledPost();
    }, [getScheduledPost]);

    const localizer = momentLocalizer(moment);

    return (
        <Paper className="flex-fill overflow-auto d-flex flex-column p-3 mb-3" elevation={3}>
            <div
                className="d-flex align-items-center px-3">
                <LocationFilter
                    setSelectedLocations={setSelectedLocations}
                    multiple={true}
                    selectedLocs={selectedLocations}
                />

                <Button variant='contained' className="ml-auto" color='primary' onClick={() => history.push('/post_scheduler')}
                    startIcon={<LineIconLight name='pencil-alt' size='sm' />}
                >
                    Create Post
                </Button>
            </div>

            <Divider className='mx-3 mt-3' />

            {
                loading || user.statsLoading ?
                    <CircularProgress size={75} className='m-auto' />
                    :
                    <div className='mt-3 px-3' style={{ height: '80%' }}>
                        <Calendar
                            localizer={localizer}
                            events={
                                scheduledPost.map(e => ({
                                    title: 'GMB POST',
                                    start: moment(e.postDate).toDate(),
                                    end: moment(e.postDate).add(60, 'seconds').toDate(),
                                    allDay: false,
                                    location: locForNoti[e.locationName],
                                    id: e._id,
                                    post: e
                                }))
                            }
                            titleAccessor={(event) => (
                                <div className='d-flex flex-row align-items-center'>
                                    <span className='ml-2'>{event.title}</span>
                                </div>
                            )}
                            startAccessor="start"
                            endAccessor="end"
                            views={['month', 'week', 'day']}
                            tooltipAccessor={(event) => event.location}
                            className='position-relative'
                            onSelectEvent={handleClick}
                        />

                        <Popper
                            open={!!anchorEl} transition anchorEl={anchorEl}
                            placement='right-end'
                            modifiers={{
                                offset: { offset: '10, 15' }
                            }}
                            style={{ zIndex: 100, position: 'relative' }}
                        >
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Card className='position-relative card' style={{ width: "18rem", zIndex: 1000, background: '#fff' }}>
                                        <CardHeader
                                            title={event?.location}
                                            subheader={moment(event.post?.postDate).format('lll')}
                                            action={
                                                <IconButton onClick={() => setAnchorEl(null)}>
                                                    <LineIconLight name='cross-circle' size='md' />
                                                </IconButton>
                                            }
                                        />
                                        {
                                            event?.post?.media?.length ?
                                                <img src={event?.post?.media[0].sourceUrl} className='img img-fluid' alt={event?.title} />
                                                :
                                                null
                                        }
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {event.post?.summary}
                                            </Typography>
                                        </CardContent>
                                        <Divider />
                                        <CardActions>
                                            <Button
                                                color='primary' variant='outlined'
                                                onClick={() => history.push(`/post_scheduler?id=${event.id}`)}
                                            >
                                                View
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Fade>
                            )}
                        </Popper>
                    </div>
            }
        </Paper>
    )
}