const initialState = {
    loading: true
};

export default function appReduces(state = initialState, action) {
    switch (action.type) {
        case 'SET_APP_LOADING': {
            return ({
                loading: action.status
            });
        }
        default: {
            return state;
        }
    }
}