import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import LineIconRegular from '../../react-lineicons-regular';

export default function Footer() {
    return (
        <div className="mt-0 footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <h2>JeeMB</h2>
                        <ul className="footer-sub">
                            <li>&copy; 2021 ClickLocal Digital Advertising LLP</li>
                            <li><Link to='/privacy'>Privacy</Link></li>
                            <li><Link to='/terms'>T&amp;C</Link></li>
                            <li><Link to='/refund_and_cancellation'>Refund &amp; Cancellation</Link></li>
                            <li><Link to='/about'>Contact</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-5 d-none d-md-flex">
                        <div className="social-icon d-flex flex-row align-items-center ml-auto">
                            <a href="https://facebook.com/teamamittiwari">
                                <div className='fa-facebook-f'><LineIconRegular name='facebook-filled' size='sm'/></div>
                            </a>
                            <a href="https://instagram.com/teamamittiwari">
                                <div className='fa-instagram'><LineIconRegular name='instagram-original'/></div>
                            </a>
                            <a href="https://facebook.com/teamamittiwari">
                                <div className='fa-youtube'><LineIconRegular name='youtube' /></div>
                            </a>
                            <a href="https://facebook.com/teamamittiwari">
                                <div className='fa-twitter'><LineIconRegular name='twitter-original'/></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}