import React, { useEffect, useState, } from 'react';
import { GoogleLogin } from 'react-google-login';
import { Button, ListItemAvatar, Checkbox, List, ListItem, Paper, Typography, ListItemText, Avatar, Divider, CircularProgress, Dialog, DialogContent, DialogActions, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import LineIconLight from '../../react-lineicons-light';

import { _fetch } from '../../netReq';
import { loadAccount, loadGoogleAccounts } from '../../redux/action/action';

const readMask = 'name,languageCode,storeCode,title,phoneNumbers,categories,storefrontAddress,websiteUri,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata,profile,relationshipData,moreHours,serviceItems'
export default function Login() {

	const [loading, setLoading] = useState(false);
	const [userData, setUserData] = useState([]);
	const [adding, setAdding] = useState(false);
	const [availableSlots, setAvailableSlots] = useState(0);
	const { account_id } = useSelector(st => st.userReducer);
	const [, setCookie] = useCookies(['account_id']);
	const [helpModal, setHelpModal] = useState(false);

	const history = useHistory();
	let dispatch = useDispatch();
	const [warning, setWarning] = useState('');

	async function handleSubmit() {

		setWarning('');
		let checked = userData
			.map(e => e.locationData
				.filter(g => g.selected === true && !g.disabled)
				.map(f => ({
					name: f.name,
					user: e.user_id,
					locationName: f.title,
					account_id: e.account_id,
				}))
			)
			.flat(1);
		if (checked.length === 0) return setWarning('Please select at least one listing.');

		if (availableSlots && checked.length <= availableSlots) {
			try {
				setAdding(true);
				let res = await _fetch(`${process.env.REACT_APP_API_URL}/location`, { method: 'POST', body: { locations: checked } });
				if (res.status === 200) {
					let result = await loadAccount(dispatch);
					let users = result.response.users;
					if (Array.isArray(users) && users.length > 0) {
						let current_user = users.find(e => e.account_id === account_id);
						if (!current_user) {
							setCookie('account_id', users[0].account_id, { maxAge: 30 * 24 * 60 * 60 });
						} else {
							await loadGoogleAccounts(dispatch, account_id, current_user);
						}
					}

					history.push('/');
				}
				else {
					process.env.NODE_ENV === 'development' && console.log(res.response)
					setWarning(res.response.message);
				}
			} catch (err) {
				process.env.NODE_ENV === 'development' && console.log(err);
			} finally {
				setAdding(false);
			}
		} else {
			setWarning(`${availableSlots > 0 ? `You can only add ${availableSlots} listing.` : 'You have reached your free trial listing limit.'} Start your subscription today to add more listings.`);
		}
	}

	const responseGoogle = async (gResponse) => {

		setLoading(true);
		_fetch(`${process.env.REACT_APP_API_URL}/users/login`, { method: 'POST', body: { code: gResponse.code } })
			.then(async result => {
				if (result.status === 200) {

					let user = result.response.user;
					let accounts = user.accounts;
					let locations = [];
					let ind = userData.findIndex(e => e.email === user.email);

					for (let i = 0; i < accounts.length; i++) {

						let tempInfo = `accounts/${accounts[i]}`;
						let locationResult = await _fetch(`${process.env.REACT_APP_GMBI_V1}${tempInfo}/locations?readMask=${readMask}`, { token: result.response.accessToken });

						if (locationResult?.locations) {

							locationResult.locations.forEach(e => {
								locations.push({
									name: e.name,
									title: e.title,
									selected: ind === -1 ? false : userData[ind].locationData.find(l => l.name === e.name).selected,
									disabled: ind === -1 ? false : userData[ind].locationData.find(l => l.name === e.name).selected
								});
							})
						}
					}

					if (ind === -1) {
						setUserData((prevValue) => ([
							...prevValue,
							{
								email: user.email,
								lastName: user.lastName,
								firstName: user.firstName,
								account_id: user.account_id,
								user_id: user.user_id,
								picture: user.picture,
								locationData: locations
							}
						]));
					} else {
						setUserData(update)(userData, { [ind]: { locationData: { $set: locations } } });
					}

				} else {
					toast.error(result.response);
					return null;
				}
			})
			.catch(error => {
				process.env.NODE_ENV === 'development' && console.log(error);
			})
			.finally(() => setLoading(false));
	};

	const onSelectLocation = (i, j) => (ev) => {
		setUserData(pv => {
			return update(pv, { [i]: { locationData: { [j]: { selected: { $set: ev.target.checked } } } } });
		})
	}

	useEffect(() => {

		setLoading(true);

		_fetch(`${process.env.REACT_APP_API_URL}/users/logged_in`)
			.then(async result => {
				let arr = [];
				process.env.NODE_ENV === 'development' && console.log(result);
				if (result.status !== 200) {
					return;
				}
				let _users = Array.isArray(result.response) ? result.response : [];
				for (let i = 0; i < _users.length; i++) {
					try {
						let resp = result.response[i];
						let accounts = resp.accounts;
						let locations = [];
						for (let i = 0; i < accounts.length; i++) {

							let tempInfo = `accounts/${accounts[i]}`;
							let locationResult = await _fetch(`${process.env.REACT_APP_GMBI_V1}${tempInfo}/locations?readMask=${readMask}`, { token: resp.access_token });

							if (locationResult?.locations) {

								locationResult.locations.forEach(e => {
									locations.push({
										name: e.name,
										title: e.title,
										selected: resp.locations.includes(e.name),
										disabled: resp.locations.includes(e.name)
									});
								})
							}
						}
						if (locations.length) {
							arr.push({
								...resp,
								locationData: locations
							});
						}
					} catch (err) {
						console.log(err);
					}
				}
				process.env.NODE_ENV === 'development' && console.log(arr);
				setUserData(arr);
			}, (error) => {
				process.env.NODE_ENV === 'development' && console.log(error);
			})
			.catch(error => {
				process.env.NODE_ENV === 'development' && console.log(error);
			})
			.finally(() => setLoading(false));

		_fetch(`${process.env.REACT_APP_API_URL}/account/available_slots`)
			.then(res => {
				let { success, response } = res;
				if (success) {
					setAvailableSlots(response.availableSlots);
				}
			})
			.catch(err => {
				console.log(err);
			});

	}, [])

	return (
		<Paper className='h-100 p-3 d-flex flex-column overflow-auto'>
			<div className='d-flex flex-column flex-md-row'>
				<Typography variant='h5' className='mb-2'>
					Add Listings <Typography variant='body2' color='textSecondary' display='inline'>(Available Slots: {availableSlots})</Typography>
				</Typography>

				{/* <IconButton
					color='primary' className='ml-auto'
					onClick={() => setHelpModal(true)}
				>
					<LineIconLight name='help' />
				</IconButton> */}
			</div>
			{
				loading
					?
					<CircularProgress size={75} className='m-auto' />
					:
					userData.length === 0
						?
						<div className='my-auto text-center'>
							<p>Login to your Google My Business Account to add listings</p>
							<GoogleLogin
								clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID}
								buttonText="Login With Google"
								onSuccess={responseGoogle}
								scope='https://www.googleapis.com/auth/plus.business.manage email profile'
								cookiePolicy={'single_host_origin'}
								prompt="consent"
								responseType='code'
								accessType='offline'
								theme='dark'
								redirectUri={process.env.REACT_APP_URL}
							/>
						</div>
						:
						<div className='rounded py-2 h-100 overflow-auto'>
							<List dense={true} style={{ height: '100%', overflow: 'auto' }}>
								{
									userData.map((e, i) => (
										<React.Fragment key={i}>
											<ListItem>
												<ListItemAvatar>
													<Avatar src={e.picture} alt={e.firstName.substr(0, 1)}></Avatar>
												</ListItemAvatar>
												<ListItemText className='d-flex flex-row align-items-center'>
													<Typography className='ml-2' variant='body1'>
														{e.firstName} {e.lastName}
													</Typography>
												</ListItemText>
											</ListItem>
											<List dense={true} className='ml-4' >
												{
													e.locationData.map((f, j) => (
														<ListItem key={j}>
															<ListItemAvatar>
																<Checkbox
																	checked={f.selected}
																	disabled={f.disabled}
																	onChange={onSelectLocation(i, j)}
																/>
															</ListItemAvatar>
															<ListItemText className='d-flex flex-row align-items-center'>
																<Typography variant='body1'>
																	{f.title}
																</Typography>
															</ListItemText>
														</ListItem>
													))
												}
											</List>
											<Divider light />
										</React.Fragment>
									))
								}
							</List>

						</div>
			}
			<div className='border-top'>
				<Typography color='error' className='m-2'>
					{warning}
				</Typography>
				<div className='d-flex'>
					<Button variant='contained' color='primary'
						onClick={handleSubmit}
						className='mr-3'
						disabled={adding}
						startIcon={adding ? <CircularProgress size={20} /> : null}
					>
						Start With JeeMB
					</Button>
					<Button variant='contained' color='primary'
						onClick={() => history.push('/buy_slots')}
					>
						Buy More Slots
					</Button>
					<div className='d-flex ml-auto'>
						<Typography
							variant='h6'
							className='my-auto mr-2'>
							Add more listing from another Google account</Typography>
						<GoogleLogin
							className='ml-auto'
							clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID}
							buttonText="Login With Google"
							onSuccess={responseGoogle}
							scope='https://www.googleapis.com/auth/plus.business.manage email profile'
							cookiePolicy={'single_host_origin'}
							prompt="consent"
							responseType='code'
							accessType='offline'
							theme='dark'
							redirectUri={process.env.REACT_APP_URL}
						/>
					</div>
				</div>
			</div>
			{/* <Dialog open={helpModal} onClose={() => setHelpModal(false)} maxWidth="xl">
				<DialogContent>
					<iframe
						width="1280" height="720" src="https://www.youtube.com/embed/n7Qe1QpSXAE"
						title="YouTube video player" frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setHelpModal(false)}
						variant='contained' color='primary'
					>
						Close
					</Button>
				</DialogActions>
			</Dialog> */}
		</Paper>
	);
}