import React, { useState } from "react"
import { Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, TextField } from "@material-ui/core";
import { _fetch } from "../../netReq";
import { toast } from "react-toastify";
import LineIconLight from "../../react-lineicons-light";

export default function JeeMBFeedback() {
    const [open, setOpen] = useState(false);
    const [extend, setExtend] = useState(false)
    const [feedback, setFeedback] = useState('');
    const [selection, setSelection] = useState('p');

    const handleSelection = (e) => {
        setSelection(e.target.value);
    }

    function handleFeedback(e) {
        e.preventDefault();
        if (feedback !== '')
            _fetch(`${process.env.REACT_APP_API_URL}/account/feedback`, {
                method: 'POST',
                body: {
                    type: selection === 'p' ? 'other' : selection,
                    feedback: feedback
                }
            })
                .then(r => {
                    if (r.success) {
                        toast.info('Thanks for your feedback')
                        setOpen(false)
                    }
                    else {
                        toast.error('Ah Oh! we are facing some issues right now, try again in some time')
                    }
                }).catch(err => {
                    process.env.NODE_ENV === 'development' && console.log(err)
                })
    }

    return (
        <React.Fragment>
            {
                extend
                    ?
                    <Fab
                        size='medium'
                        variant='extended'
                        onMouseLeave={() => setExtend(false)}
                        color='primary'
                        className='position-fixed'
                        style={{right: 30, bottom: 30}}
                        onClick={() => setOpen(true)}
                    >
                        <LineIconLight name='speech-bubble-35' className='mr-2' />
                        Send Feedback
                    </Fab>
                    :
                    <Fab
                        size='medium'
                        onMouseOver={() => setExtend(true)}
                        variant='round'
                        color='primary'
                        className='position-fixed'
                        style={{right: 30, bottom: 30}}
                        onClick={() => setOpen(true)}
                    >
                        <LineIconLight name='speech-bubble-35' />
                    </Fab>
            }
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle >
                    <div className='d-flex justify-content-between align-items-center'>
                        <h6>
                            We'll love to have your feedback
                        </h6>
                        {/* <FormControl className='w-50' variant='outlined'> */}
                        {/* <InputLabel htmlFor="outlined-adornment-password">Type</InputLabel> */}
                        <Select
                            style={{ width: '30%' }}
                            size='small'
                            value={selection}
                            onChange={handleSelection}
                        >
                            <MenuItem value='p' disabled>Select Type</MenuItem>
                            <MenuItem value='billing'>Billing & Payment</MenuItem>
                            <MenuItem value='account'>Account Related</MenuItem>
                            <MenuItem value='technical'>Technical Issue</MenuItem>
                            <MenuItem value='feature'>Feature Request</MenuItem>
                            <MenuItem value='other'>Other</MenuItem>
                        </Select>
                        {/* </FormControl> */}
                    </div>
                </DialogTitle>
                <form onSubmit={handleFeedback}>
                    <DialogContent>
                        <TextField className='w-100'
                            variant='outlined'
                            value={feedback}
                            label='Feedback'
                            placeholder='Type your Feedback...'
                            onChange={(e) => setFeedback(e.target.value)}
                            multiline
                            rows={6}
                            rowsMax={6}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' type='submit' color='primary' className='mx-3 mb-2'>Send</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment >
    )
}