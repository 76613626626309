import {
	Grow, Zoom, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
	Grid, ListItem, ListItemSecondaryAction, ListItemText, Paper, Tooltip, Typography,
	IconButton, MenuItem, Hidden, Menu, ListItemIcon, TextField
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import 'loaders.css';
import { red as colorRed, green, yellow, blue, grey } from "@material-ui/core/colors";
import { toast } from "react-toastify";
import { useMixpanel } from 'react-mixpanel-browser';
import { exportComponentAsPNG } from "react-component-export-image";

import ReviewMacro from "./macroView";
import '../styles.css';
import { updateGUR, setLocationStats, updateOpenStatus, updateNickName } from "../../../redux/action/action";
import { _fetch } from "../../../netReq";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { IOSSwitch } from "../../../resources/IOSSwitch/IOSSwitch";
import { primaryColor } from '../../../theme';
import LineIconLight from "../../../react-lineicons-light";

var QRCode = require('qrcode.react');

const StatsComponent = ({ label, Icon, stat, loading, onClick, gradient }) => {
	return (
		<Grid item xs={6} md={3}>
			<div
				className='justify-content-around pointer rounded py-2 px-1 d-flex stats'
				style={gradient}
				onClick={onClick}
			>
				<div className='d-flex flex-column justify-content-center'>
					{
						loading ?
							<div className="loader loaders loader-small">
								<div className='loader-inner ball-beat'>
									<div></div>
									<div ></div>
									<div></div>
								</div>
							</div>
							:
							<Typography className='text-light m-auto'>
								{stat}
							</Typography>
					}
					<Typography className='text-light' variant='button'>{label}</Typography>
				</div>
				<div className='my-auto font-scaling text-white'>
					<Icon />
				</div>
			</div>
		</Grid>
	)
}

const ListingCard = ({ e, user_id, userName, index, statsLoading }) => {

	const history = useHistory();
	const [recommendationsVisible, setRecommendationsVisible] = useState(false);
	const [GURActive, setGURActive] = useState(false)
	const dispatch = useDispatch();
	const red = useSelector(state => state.userReducer);
	const mixpanel = useMixpanel();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const ref = useRef();
	const [openOpenStatusDialog, setOpenOpenStatusDialog] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [menuOpen, setMenuOpen] = useState(null);
	const [nickName, setNickName] = useState(null);
	const shortCodeRef1 = useRef(null);
	const shortCodeRef3 = useRef(null);

	useEffect(() => {
		if (!e.GUR.active) setGURActive(false)
		else setGURActive(true)
	}, [e.GUR.active]);

	function handleStats() {
		setLoading(true);
		_fetch(`${process.env.REACT_APP_API_URL}/location/stats?name=${JSON.stringify([e.name])}&forceReload=true`)
			.then(r => {
				if (r.success) {
					dispatch(setLocationStats({ index, ...r.response[0] }));
				}
			})
			.catch(err => {
				process.env.NODE_ENV === 'development' && console.log(err);
			})
			.finally(() => setLoading(false));
	}

	function handleGUR() {
		mixpanel.track('switch flip', { status: !GURActive, locationId: e.locationId, action: 'change_auto_reject_status' });
		_fetch(`${process.env.REACT_APP_API_URL}/location/gur`, {
			method: 'PATCH',
			body: {
				userName: userName,
				locationId: e.locationId,
				active: !GURActive
			}
		})
			.then(r => {
				process.env.NODE_ENV === 'development' && console.log(r)
				if (r.success) {
					setGURActive(r.response.active);
					dispatch(updateGUR({ index: index, active: r.response.active, userName: r.response.userName }))
					process.env.NODE_ENV === 'development' && console.log(red)
				}
			}).catch(err => {
				toast.error(err)
				process.env.NODE_ENV === 'development' && console.log(err)
			})
	}

	function handleOpenStatus() {
		let body = { openInfo: {} };

		if (!e && !e.openInfo)
			return;
		else if (e?.openInfo?.status === 'OPEN' || e?.openInfo?.status === 'OPEN_FOR_BUSINESS_UNSPECIFIED')
			body.openInfo.status = 'CLOSED_TEMPORARILY';
		else
			body.openInfo.status = 'OPEN';
		_fetch(`${process.env.REACT_APP_GMBI_V1}${e.name}?updateMask=openInfo.status`, {
			method: 'PATCH',
			body: body,
			token: red.accessToken
		})
			.then((result) => {
				if (result)
					if (result.status === 200) {
						dispatch(updateOpenStatus({ index, status: body.openInfo.status }))
					}
					else
						toast.error('Some error occurred')
			}, (error) => {
				console.log(error)
				toast.error('Some error occurred')
			}).catch(err => {
				toast.error('Some error occurred');
				console.log(err)
			})
		setOpenOpenStatusDialog(false);
	}

	function saveNickName() {
		_fetch(`${process.env.REACT_APP_API_URL}/location/nickname`, {
			method: 'POST',
			body: {
				nickName,
				locationId: e.locationId
			}
		})
			.then(res => {
				if (res.success) {
					toast.success('Nick Name added successfully');
					dispatch(updateNickName({ index, nickName }))
					setDialogOpen(false);
				} else {
					toast.error(res.response);
				}
			})
			.catch(err => {
				console.log(err);
				toast.error('Oops! some error occurred');
			})
	}

	function copy(ref) {
		let range;
		range = document.createRange();
		range.selectNode(ref.current);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);
		document.execCommand("copy");
		window.getSelection().removeAllRanges();
		toast.info("Link copied !");
	}

	let stats = [{
		label: 'Average Rating',
		stat: e.averageRating,
		gradient: { background: 'linear-gradient(45deg, #6e5acb 30%, #d553ab 90%)' },
		// statColor: e.averageRating < 3 ? colorRed['800'] : e.averageRating < 4.5 ? green['A700'] : blue['A700'],
		Icon: () => (<LineIconLight size='xl' name='star' className='mr-1' />),
		onClick: () => history.push('/reviews')
	}, {
		label: 'Total Ratings',
		stat: e.totalRatings,
		gradient: { background: 'linear-gradient(45deg, #11998e 30%, #38ef7d 90%)' },
		// statColor: e.totalRatings < 1 ? colorRed['800'] : blue['A700'],
		Icon: () => (<LineIconLight size='xl' name='division-alt-1' className='mr-1' />),
		onClick: () => history.push('/reviews')
	}, {
		label: 'Total Questions',
		stat: e.totalQuestions,
		gradient: { background: 'linear-gradient(45deg, #e0814d 30%, #dfc560 90%)' },
		// statColor: e.totalQuestions < 1 ? colorRed['800'] : blue['A700'],
		Icon: () => (<LineIconLight size='xl' name='user-question' className='mr-1' />),
		onClick: () => history.push(`/questions?id=${e.locationId}`)
	}, {
		label: 'Scheduled Post',
		stat: e.totalScheduledPost,
		gradient: { background: 'linear-gradient(45deg, #5B86E5 30%, #36D1DC 90%)' },
		// statColor: e.totalScheduledPost < 1 ? colorRed['800'] : blue['A700'],
		Icon: () => (<LineIconLight size='xl' name='clock-alt-1' className='mr-1' />),
		onClick: () => history.push('/scheduled')
	}];

	const size = 220;
	const thickness = 40;
	const radius = (size - thickness) / 2

	let url = `jeemb.com/s/${e.short_code}`

	return (
		<Paper className='mb-3 py-2 px-3 listing-card' elevation={3}>

			{/* LISTING CARD HEAD */}

			<ListItem>

				<ListItemText
					primary={(
						<div className='d-flex'>
							<span
								// onClick={() => history.push(`info?name=${e.name}`)}
								className='align-baseline'
								style={{ cursor: 'pointer' }}
							>
								{e.title}
							</span>
							<Typography variant='caption' onClick={() => setDialogOpen(true)} className='mx-1 mb-2 pointer align-middle'>
								{e.nickName ? `(${e.nickName})` : '(add nickname)'}
							</Typography>
							{
								e.locationState?.isVerified &&
								<Tooltip title='This location has been verified by google'>
									<span className='ml-1 align-bottom'>
										<LineIconLight size='sm' name='check-circle' style={{ color: blue['A700'] }} />
									</span>
								</Tooltip>
							}
							{
								e.locationState?.isDuplicate &&
								<Tooltip title='This location has been marked duplicate by google'>
									<span className='ml-1'>
										<LineIconLight size='sm' name='warning' style={{ color: yellow['800'] }} />
									</span>
								</Tooltip>
							}
							{
								e.locationState?.isSuspended &&
								<Tooltip title='This location has been suspended by google'>
									<span className='ml-1'>
										<LineIconLight size='sm' name='warning' style={{ color: colorRed['800'] }} />
									</span>
								</Tooltip>
							}
						</div>
					)}
				/>

				<ListItemSecondaryAction className='d-flex flex-column flex-sm-row justify-content-between'>

					<Hidden only={['xs', 'sm']}>
						<Tooltip title={`Location is ${e?.openInfo?.status?.split('_').join(' ')}`}>
							<span>
								<IconButton disabled={statsLoading} onClick={() => setOpenOpenStatusDialog(true)} >
									<LineIconLight
										size='md' name='power-switch'
										style={{
											color: statsLoading ? grey['500'] : e.openInfo?.status === 'OPEN' || e.openInfo?.status === 'OPEN_FOR_BUSINESS_UNSPECIFIED' ? green['500'] : colorRed['500']
										}}
									/>
								</IconButton>
							</span>
						</Tooltip>
						<Tooltip title='Refresh listing data'>
							<IconButton className='mx-1' onClick={handleStats}>
								<LineIconLight size='md' name='reload' />
							</IconButton>
						</Tooltip>
					</Hidden>

					<Hidden mdUp>
						<IconButton onClick={(ev) => setMenuOpen(ev.currentTarget)}>
							<LineIconLight name='menu-circle' style={{ cursor: 'pointer' }} />
						</IconButton>
					</Hidden>

					<Dialog open={openOpenStatusDialog} onClose={() => setOpenOpenStatusDialog(false)}>
						<DialogTitle>
							{e.title}
						</DialogTitle>
						<DialogContent>
							<Typography>
								Are you sure that you want to mark your location as {e.openInfo?.status === 'OPEN' || e.openInfo?.status === 'OPEN_FOR_BUSINESS_UNSPECIFIED' ? 'Temporarily Closed' : 'Open'}?
							</Typography>
							<DialogActions>
								<Button variant='contained' color='primary' onClick={() => setOpenOpenStatusDialog(false)}>Cancel</Button>
								<Button variant='contained' color='primary' onClick={handleOpenStatus}>Confirm</Button>
							</DialogActions>
						</DialogContent>
					</Dialog>

					<Menu
						anchorEl={menuOpen}
						getContentAnchorEl={null}
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
						transformOrigin={{ vertical: "top", horizontal: "right" }}
						open={Boolean(menuOpen)}
						onClose={() => setMenuOpen(null)}
					>
						<MenuItem onClick={() => setOpenOpenStatusDialog(true)} disabled={statsLoading}>
							<ListItemIcon>
								<LineIconLight
									size='md' name='power-switch'
									style={{
										color: statsLoading ? grey['500'] : e.openInfo?.status === 'OPEN' || e.openInfo?.status === 'OPEN_FOR_BUSINESS_UNSPECIFIED' ? green['500'] : colorRed['500']
									}}
								/>
							</ListItemIcon>
							<Typography>{e?.openInfo?.status.split('_').join(' ')}</Typography>
						</MenuItem>
						<MenuItem onClick={() => { handleStats(); setMenuOpen(null); }}>
							<ListItemIcon>
								<LineIconLight size='md' name='reload' />
							</ListItemIcon>
							<Typography variant="inherit">Refresh</Typography>
						</MenuItem>
					</Menu>

					<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">Let's give a nick name to your Listing</DialogTitle>
						<DialogContent>
							<TextField
								autoFocus
								margin="dense"
								id="name"
								label="Nick Name"
								type="text"
								fullWidth
								defaultValue={e.nickName}
								onChange={ev => setNickName(ev.target.value)}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => { setNickName(''); setDialogOpen(false); }} color="primary">
								Cancel
							</Button>
							<Button onClick={saveNickName} color="primary">
								Save
							</Button>
						</DialogActions>
					</Dialog>


				</ListItemSecondaryAction>

			</ListItem>

			{/* LISTING CARD BODY */}

			<Grid container className='mt-2' spacing={2}>

				{
					stats.map((e, i) => <StatsComponent {...e} key={i} loading={statsLoading || loading} />)
				}

				<Grid item container lg={12} xs={12}>
					<Grid
						item lg={4} md={4} xs={12} style={{ cursor: `${e.recommendations?.length ? 'pointer' : ''}` }}
						onClick={() => e.recommendations?.length && setRecommendationsVisible(!recommendationsVisible)}
					>

						<Box position="relative" display="inline-flex" className='d-flex justify-content-center p-2'>
							{
								statsLoading || loading ?
									<div className='loader loaders d-flex' style={{ height: '220px' }}>
										<div className='loader-inner ball-scale my-auto'>
											<div></div>
										</div>
									</div>
									:
									<div >

										<svg width={size} height={size}>
											{
												e.score < 70
													?
													<linearGradient id={`linearColors1-${e.name}`} x1="0" y1="0" x2="1" y2="1">
														<stop offset="0%" stopColor="#e0814d"></stop>
														<stop offset="100%" stopColor="#dfc560"></stop>
													</linearGradient>
													:
													e.score < 90
														?
														<linearGradient id={`linearColors1-${e.name}`} x1="0" y1="0" x2="1" y2="1">
															<stop offset="0%" stopColor="#11998e"></stop>
															<stop offset="100%" stopColor="#38ef7d"></stop>
														</linearGradient>
														:
														<linearGradient id={`linearColors1-${e.name}`} x1="0" y1="0" x2="1" y2="1">
															<stop offset="0%" stopColor="#6e5acb"></stop>
															<stop offset="100%" stopColor="#d553ab"></stop>
														</linearGradient>

											}
											<linearGradient id="linearColors2" x1="0" y1="1" x2="1" y2="0">
												<stop offset="0%" stopColor="#f1f2f0"></stop>
												<stop offset="100%" stopColor="#f1f2f0"></stop>
											</linearGradient>
											<path d={`M ${size / 2} ${thickness / 2} a ${radius} ${radius} 0 ${e.score > 50 ? '1' : '0'} 1 ${radius * Math.sin(2 * Math.PI * (e.score / 100))} ${radius * (1 - Math.cos(2 * Math.PI * (e.score / 100)))}`}
												fill="none" stroke={`url(#linearColors1-${e.name})`} strokeWidth={thickness} />
											<path d={`M ${size / 2 + radius * Math.sin(2 * Math.PI * (e.score / 100))} ${thickness / 2 + radius * (1 - Math.cos(2 * Math.PI * (e.score / 100)))} a ${radius} ${radius} 0 ${e.score > 50 ? '0' : '1'} 1 ${radius * Math.sin(2 * Math.PI * (1 - e.score / 100))} -${radius * (1 - Math.cos(2 * Math.PI * (1 - e.score / 100)))}`}
												fill="none" stroke={`url(#linearColors2-${e.name})`} strokeWidth={thickness} />
										</svg>

										<Box
											top={0}
											left={0}
											bottom={0}
											right={0}
											position="absolute"
											display="flex"
											className='d-flex flex-column align-items-center'
											alignItems="center"
											justifyContent="center"
										>

											<Typography variant='h4' component="div"
												style={{ color: e.score < 70 ? yellow['800'] : e.score < 90 ? green['A700'] : primaryColor }}
											>
												{e.score}
											</Typography>
											<Typography variant='body2'>
												Health Score
											</Typography>
										</Box>
									</div>
							}
						</Box>
					</Grid>
					{
						recommendationsVisible && e.recommendations.length ?
							<Grow in>
								<div className='p-3'>
									<h5>Recommendations</h5>
									<ul className='overflow-auto'>
										{
											Array.isArray(e.recommendations) && e.recommendations.map((e, i) => (
												<li key={i} className='my-1'>
													<Typography>{e}</Typography>
												</li>
											))
										}
									</ul>
								</div>
							</Grow>
							:
							<Zoom in>
								<Grid container item xs={12} md={8} alignItems='center' className='px-4 d-flex'>

									{/* AUTO REJECT */}

									<Grid item lg={12} md={12} xs={12} className='d-flex align-items-center justify-content-between'>
										<Typography className='d-flex'>
											{
												GURActive ?
													<LineIconLight size='md' name='shield-alt-1-check' style={{ color: primaryColor }}
														className='mr-3 my-auto' />
													:
													<LineIconLight size='md' name='shield-alt-1'
														className='mr-3' />
											}
											Auto - reject edit suggestions
										</Typography>
										<IOSSwitch checked={GURActive} onClick={handleGUR} />
									</Grid>

									{/* AUTO REVIEW REPLY */}

									<Grid item lg={12} md={12} xs={12}>
										<ReviewMacro user_id={user_id} locationId={e.locationId} active={e.reviewMacroActive} index={index} />
									</Grid>

									{/* SHORT CODE */}

									<Grid item lg={12} md={12} xs={12} >
										{
											e.short_code ?
												<div className='my-auto w-100 justify-content-between d-flex'>
													<div className='d-flex'>
														<LineIconLight size='md' name='scan' className='mr-3 my-auto' style={{ color: primaryColor }} />
														<a
															href={`${process.env.REACT_APP_API_URL}/s?code=${e.short_code}`}
															rel='noopener noreferrer'
															target='_blank' ref={shortCodeRef1}
															className='my-auto'
														>
															<Typography className='d-inline'>
																jeemb.com/s/{e.short_code}
															</Typography>
														</a>
													</div>
													<div>
														<Tooltip title='Download QR Code'>
															<IconButton onClick={() => setOpen(true)} >
																<LineIconLight size='sm' name='download' />
															</IconButton>
														</Tooltip>
														<Tooltip title='Copy Review ShortCode'>
															<IconButton onClick={() => copy(shortCodeRef1)}>
																<LineIconLight size='sm' name='copy' />
															</IconButton>
														</Tooltip>
													</div>
												</div>
												:
												''
										}
									</Grid>

								</Grid>
							</Zoom>
					}
				</Grid>

			</Grid>

			{/* QR CODE DIALOG */}

			<Dialog onClose={() => setOpen(false)} open={open}>

				<DialogTitle id="customized-dialog-title" >
					<div className='d-flex justify-content-between'>
						<h6 className='my-auto'>Google Review Qr Code</h6>
						<IconButton
							size='small'
							className='p-2'
							onClick={() => setOpen(false)}
						>
							<LineIconLight name='close' size='xs' />
						</IconButton>
					</div>
				</DialogTitle>

				<DialogContent dividers className='d-flex flex-column'>
					<div
						style={{
							width: 400,
							height: 600,
							padding: 10
						}}
						ref={ref}
					>
						<div className='d-flex flex-column p-3'
							style={{
								width: 380,
								height: 580,
								borderRadius: 15,
								border: '1px #dee2e6 solid',
								borderBottom: `5px solid ${primaryColor}`
							}}
						>
							<Typography variant='h3' color='primary' className='text-center'>SCAN ME</Typography>
							<h4 className='text-center border-bottom pb-2 mb-3'>to review &amp; support</h4>
							<h5 className='text-center mb-2'>{e.title}</h5>
							<h5 className='text-center mb-3' style={{ fontWeight: 400 }}>{e.primaryPhone ? `Phone no.: ${e.primaryPhone}` : <span>&nbsp;</span>}</h5>

							<div className='my-auto text-center'>
								<QRCode
									value={`https://jeemb.com/s/${e.short_code}`}
									size={200} level='H' className='mx-auto'
									bgColor='transparent'
									imageSettings={{
										src: '/jeembLogo.png',
										width: 35,
										height: 35,
										excavate: true
									}}
								/>
								<p className='mt-3 mb-1 text-center' ref={shortCodeRef3}>https://jeemb.com/s/{e.short_code}</p>
								<p className='text-center'>Open your camera app &amp; scan</p>
							</div>

							<h4 className='text-center'>Powered By JeeMB</h4>
							<p className='text-center mb-0'>Manage, Protect &amp; Promote Your Google Listing</p>

						</div>
					</div>
				</DialogContent>

				<DialogActions className='justify-content-between'>
					<div className='d-flex align-items-center'>
						<Tooltip title='Share short link on Twitter'>
							<TwitterShareButton
								className='mr-1'
								url={url}
								title='Click on this link to review my Business on Google.'
								hashtags={['JeeMB', 'GMBReview', 'GMB', 'GoogleMyBusiness']}
							>
								<TwitterIcon size={30} round={true} />
							</TwitterShareButton>
						</Tooltip>

						<Tooltip title='Share short link on Whatsapp'>
							<WhatsappShareButton
								className='mr-1'
								url={url}
								title='Click on this link to review my Business on Google.'
							>
								<WhatsappIcon size={30} round={true} />
							</WhatsappShareButton>
						</Tooltip>

						<Tooltip title='Share short link on Facebook'>
							<FacebookShareButton
								className='mr-1'
								url={url}
								quote='Review me on Google by clicking this link'
								hashtag='#JeeMB'
							>
								<FacebookIcon size={30} round={true} />
							</FacebookShareButton>
						</Tooltip>

						<Tooltip title='Copy short link'>
							<div
								className='d-flex'
								style={{ borderRadius: '100%', width: '30px', height: '30px', background: blue['A700'] }}
								onClick={() => copy(shortCodeRef3)}
							>
								<LineIconLight name='copy' size='sm' className='m-auto' style={{ color: 'white' }} />
							</div>
						</Tooltip>
					</div>
					<div className='d-flex'>
						<Tooltip title='Download QR code'>
							<Button
								color='primary'
								size='small'
								variant='contained'
								onClick={() => exportComponentAsPNG(ref, { fileName: e.title })}
								startIcon={<LineIconLight name='download' size='xs' />}
							>
								Download
							</Button>
						</Tooltip>
					</div>
				</DialogActions>

			</Dialog>

		</Paper>
	)
}

export default ListingCard;