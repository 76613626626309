import React, { useState } from 'react';
import { Button, CircularProgress, Paper } from '@material-ui/core';
import './style.css';
import { FaFacebookF, FaInstagram, FaYoutube, FaTwitter } from 'react-icons/fa';
import { _fetch } from '../../netReq';
import { toast } from 'react-toastify';
import LineIconLight from '../../react-lineicons-light';
import { primaryColor } from '../../theme';

export default function About() {

    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [err, setErr] = useState('');
    const [loading, setLoading] = useState(false);

    function onChange(ev) {
        ev.target.setCustomValidity('');
        let { name, value } = ev.target;
        setData({ ...data, [name]: value });
    }

    function onsubmit(ev) {
        ev.preventDefault();
        for (let key in data) {
            if (!data[key]) {
                setErr(`${key} is required`);
                return;
            }
        }
        setLoading(true);
        _fetch(`${process.env.REACT_APP_API_URL}/account/support_ticket`, {
            method: 'POST',
            body: data
        })
            .then(res => {
                if (res.status === 200) {
                    toast.success('Thank you for contacting us, we will get back to you soon.')
                } else {
                    toast.error('Oh O! some error occurred');
                }
            })
            .catch(err => {
                toast.error('Oh O! some error occurred, please check your network connectivity.');
            })
            .finally(() => {
                setLoading(false);
            })
        return false;
    }

    return (
        <Paper className='w-75 my-auto mx-auto p-4'>
            <div className="row ">
                <div className="col-sm-6 ">
                    <div className="contact-form wow fadeInDown">
                        <div className="contact-frm">
                            <h1>Address</h1>
                            <ul style={{ listStyle: 'none' }} className='pl-0'>
                                <li>
                                    <a href="mailto:customer@jeemb.com" className='d-flex flex-row'>
                                        <LineIconLight name='message-check' size='md' className='mr-3' style={{color: primaryColor}} />
                                        <span>support@jeemb.com</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:+91-11-4023 1041" className='d-flex flex-row'>
                                        <LineIconLight name='phone-ring' size='md' className='mr-3' style={{color: primaryColor}} />
                                        <span>+91-11-4023 1041</span>
                                    </a>
                                </li>
                                {
                                    <li>
                                        {
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a href='#' className='d-flex flex-row'>
                                            <LineIconLight name='building-alt' size='md' className='mr-3' style={{color: primaryColor}} />
                                                <span> Flat 271, First floor, pocket 2, sector 24, Rohini</span>
                                            </a>
                                        }
                                    </li>
                                }
                            </ul>
                            <div className="social-icon d-flex flex-row">
                                <a href="https://facebook.com/teamamittiwari">
                                    <div className='fa-facebook-f'><FaFacebookF size={20} /></div>
                                </a>
                                <a href="https://instagram.com/teamamittiwari">
                                    <div className='fa-instagram'><FaInstagram size={20} /></div>
                                </a>
                                <a href="https://facebook.com/teamamittiwari">
                                    <div className='fa-youtube'><FaYoutube size={20} /></div>
                                </a>
                                <a href="https://facebook.com/teamamittiwari">
                                    <div className='fa-twitter'><FaTwitter size={20} /></div>
                                </a>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="col-sm-6 ">
                    <div className="contact-form wow fadeInDown">

                        <div className="contact-frm">
                            <h1>Get In Touch</h1>
                        </div>

                        <form id="contact-form" onSubmit={onsubmit}>

                            <div className="messages"></div>

                            <div className="controls">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="form_name">Name*</label>
                                            <input
                                                id="form_name" type="text" name="name" className="form-control"
                                                onInvalid={(p) => { p.currentTarget.setCustomValidity('Firstname is required.') }}
                                                required={true} onChange={onChange}
                                            />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="form_email">Email *</label>
                                            <input
                                                id="form_email" type="email" name="email" className="form-control"
                                                onInvalid={(p) => { p.currentTarget.setCustomValidity('Valid email is required.') }}
                                                required={true} onChange={onChange}
                                            />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="form_phone">Phone *</label>
                                            <input
                                                id="formphone" type='tel' name="phone" className="form-control"
                                                onInvalid={(p) => { p.currentTarget.setCustomValidity('Valid phone Number is required.') }}
                                                required={true} onChange={onChange}
                                            />
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="form_message">Message *</label>
                                            <textarea
                                                id="form_message" name="message" className="form-control" rows="4"
                                                onInvalid={(p) => { p.currentTarget.setCustomValidity('Please,leave us a message.') }}
                                                required={true} onChange={onChange}
                                            >
                                            </textarea>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {err ? <p className='text-danger'>{err}</p> : null}
                                        <Button color='primary' variant='contained' type='submit' disabled={loading}>
                                            {loading && <CircularProgress size={25} />} Get In Touch
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Paper >
    );
}