import { Avatar, Badge, Button, CardHeader, Checkbox, CircularProgress, Divider, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, Slider, Tooltip, Typography } from "@material-ui/core";
import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useStripe, useElements, CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { blue } from "@material-ui/core/colors";
import { useHistory } from "react-router";
import LineIconLight from '../../react-lineicons-light'
import LineIconRegular from '../../react-lineicons-regular'

import { _fetch } from "../../netReq";
import { primaryColor } from "../../theme";

const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                fontFamily: 'Montserrat, sans-serif',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            },
        },
        hideIcon: false
    }
};

function CardComponent(card) {
    switch (card) {
        case 'visa':
            return <LineIconLight name='visa' style={{ color: primaryColor }} />
        case 'mastercard':
            return <LineIconRegular name='mastercard' style={{ color: primaryColor }} />
        case 'discover':
            return <LineIconRegular name='mastercard' style={{ color: primaryColor }} />
        case 'amex':
            return <LineIconLight name='amex' style={{ color: primaryColor }} />
        default:
            return <LineIconLight name='credit-card' style={{ color: primaryColor }} />
    }
}

export default function BuySlots() {

    const red = useSelector(st => st.userReducer);
    const [pricing, setPricing] = useState([]);
    const [payment_methods, setPaymentMethods] = useState('');
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [err, setErr] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const [payment_method, setPaymentMethod] = useState(undefined);
    const [subscribing, setSubscribing] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const history = useHistory();
    const [isCardValid, setIsCardValid] = useState(false);
    const [to_be_paid, setToBePaid] = useState(false);

    const steps = [
        {
            label: 'Select Slots',
            Icon: () => <LineIconRegular name='cart' style={{ color: 'grey' }} />
        }, {
            label: 'Payment Details',
            Icon: () => <LineIconRegular name='rupee-card' style={{ color: 'grey' }} />
        }, {
            label: 'Add Listings',
            Icon: () => <LineIconRegular name='hospital-location' style={{ color: 'grey' }} />
        }
    ];

    const getSubscription = useCallback(async () => {
        try {
            let { response, success } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/subscription`);
            if (success) {
                setSubscription(response);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);

    const startSubscription = async () => {
        try {

            if (!isCardValid && !payment_method) return setErr('Please provide a valid payment method');

            setErr('');

            let method = 'POST';

            let options = {
                method: method,
                body: {
                    purchase: pricing.map(e => ({ quantity: e.quantity, name: e.name }))
                }
            };

            setSubscribing(true);
            let { success, response } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/subscription`, options);
            if (success) {

                let pm;

                if (payment_method) {
                    pm = payment_method;
                } else {
                    pm = {
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: red.account.name
                        },
                    };
                }
                try {
                    let res = await stripe.confirmCardPayment(response.client_secret, {
                        payment_method: pm
                    });
                    if (res.error) {
                        toast.error(res.error.message);
                        setErr(res.error.message);
                        return;
                    }
                } catch (err) {
                    console.log(err);
                    toast.error(err.message);
                    return;
                }

                await getSubscription();
                setActiveStep(2);
            }
            else {
                setErr(response || (subscription ? "Subscription update failed" : "Subscription initiation failed"));
            }
        } catch (err) {
            console.log(err);
            toast.error('Some error occurred please try again later.');
        } finally {
            setSubscribing(false);
        }
    }

    async function calculateProration() {

        if (pricing.every(e => e.subscribed_quantity === e.quantity)) {
            toast.error(`Please add or remove slots to update subscription`);
            return;
        }

        setSubscribing(true);
        try {
            let options = {
                method: 'POST',
                body: {
                    purchase: pricing.map(e => ({ quantity: e.quantity, name: e.name, id: subscription.items.data.find(p => p.price.product === e.name)?.id }))
                }
            };
            let { success, response } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/subscription/preview`, options);
            if (success) setToBePaid(response);
            setActiveStep(1);
        } catch (err) {
            console.log(err);
        } finally {
            setSubscribing(false);
        }
    }

    const updateSubscription = async () => {
        try {

            setErr('');

            let method = 'PATCH';

            let options = {
                method: method,
                body: {
                    purchase: pricing.map(e => ({ quantity: e.quantity, name: e.name, id: subscription.items.data.find(p => p.price.product === e.name)?.id }))
                }
            };

            setSubscribing(true);
            let { success, response } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/subscription`, options);
            if (success) {

                let { client_secret, message } = response;
                let pm = subscription.default_payment_method;

                if (client_secret) {
                    await stripe.confirmCardPayment(client_secret, {
                        payment_method: pm
                    });
                } else {
                    toast.success(message);
                }
                setActiveStep(2);
                await getSubscription();
            }
            else {
                setErr(response || (subscription ? "Subscription update failed" : "Subscription initiation failed"));
            }
        } catch (err) {
            console.log(err);
            toast.error('Some error occurred please try again later.');
        } finally {
            setSubscribing(false);
        }
    }

    const getPaymentMethod = useCallback(async () => {
        try {
            setLoading(true);
            let { response, success } = await _fetch(`${process.env.REACT_APP_API_URL}/payment/payment_method`);
            if (success) {
                setPaymentMethods(response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, []);

    const getPricing = async () => {
        try {
            setLoading(true);
            let { response, success } = await _fetch(`${process.env.REACT_APP_API_URL}/price`);
            if (success && Array.isArray(response)) {
                setPricing(response);
            } else {
                toast.error('Some error occurred, please try again. If problem persists contact support.');
            }
        } catch (err) {
            console.log(err)
            toast.error('Some error occurred, please try again. If problem persists contact support.');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getPaymentMethod();
        getSubscription();
        getPricing()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let total = 0;
        pricing.forEach(e => { total += e.quantity * e.netPrice });
        setTotal(total);
    }, [pricing]);

    async function onChange(val, ind) {
        if (isNaN(val) || val < 0) return;
        if (pricing[ind].name === 'LISTING_SLOT' && val < (pricing[ind].moq)) {
            toast.dismiss();
            return toast.error(`You must buy at least ${pricing[ind].moq} ${pricing[ind].product_name} or remove some listings`);
        }
        setPricing((prev) => {
            return update(prev, { [ind]: { quantity: { $set: parseInt(val) } } })
        });
    }

    return (
        <Paper className='d-flex flex-column w-100 m-auto h-100'>

            <Grid container>
                {
                    steps.map(({ label, Icon }, i) => (
                        <Grid item xs={4} style={{ backgroundColor: activeStep === i ? '#e8c335' : 'lightgrey' }} key={i}>
                            <CardHeader
                                avatar={<Avatar style={{ backgroundColor: '#fff' }}><Icon /></Avatar>}
                                title={
                                    <Typography
                                        className={`${activeStep === i ? 'text-white' : 'text-light'}`}
                                        style={{ fontWeight: 600 }}
                                    >
                                        {label}
                                    </Typography>
                                }
                            />
                        </Grid>
                    ))
                }
            </Grid>

            {
                loading ?
                    <CircularProgress className='m-auto' size='large' />
                    :
                    <React.Fragment>

                        <div className='d-flex flex-column px-5 pt-4'>

                            {
                                activeStep === 0 &&
                                <React.Fragment>

                                    <div>

                                        {
                                            pricing.map((e, i) => (
                                                <React.Fragment key={i}>
                                                    <Typography variant='h6' id="select-slots" gutterBottom className='mb-5'>
                                                        {e.product_name}
                                                    </Typography>
                                                    <Slider
                                                        defaultValue={e.quantity}
                                                        step={1}
                                                        marks
                                                        valueLabelDisplay="on"
                                                        min={1}
                                                        max={20}
                                                        onChange={(ev, val) => onChange(val, i)}
                                                        aria-labelledby="select-slots"
                                                    />
                                                </React.Fragment>
                                            ))
                                        }

                                    </div>

                                    <Typography variant='h6' className='mt-4'>
                                        <Divider className='my-3' />
                                        <Grid container>
                                            <Grid item xs={8}>
                                                JeeMB slot price/listing
                                            </Grid>
                                            <Grid item xs={4}>
                                                ₹{Array.isArray(pricing) && pricing.length > 0 ? pricing[0].netPrice : 0}
                                            </Grid>
                                        </Grid>
                                        <Divider className='my-3' />
                                        <Grid container>
                                            <Grid item xs={8}>Monthly Subscription Total</Grid>
                                            <Grid item xs={4}>₹{total}</Grid>
                                        </Grid>
                                        <Divider className='my-3' />
                                    </Typography>

                                    <Typography variant='caption' className='mb-4'>
                                        Note: Select the number of listings you want to manage per month.
                                    </Typography>

                                    <div className='d-flex'>
                                        <Button
                                            variant='contained' color='primary'
                                            disabled={true} className='mr-3 ml-auto'
                                            startIcon={subscribing ? <CircularProgress size={20} /> : null}
                                        >
                                            Back
                                        </Button>

                                        <Button variant='contained' color='primary'
                                            onClick={() => {
                                                if (!subscription)
                                                    setActiveStep(1);
                                                else calculateProration();
                                            }}
                                            disabled={pricing.every(e => e.moq > e.quantity) || subscribing}
                                            startIcon={subscribing ? <CircularProgress size={20} /> : null}
                                        >
                                            Next
                                        </Button>
                                    </div>

                                </React.Fragment>
                            }

                            {
                                activeStep === 1 && !subscription &&
                                <React.Fragment>

                                    <Typography variant='h6'>
                                        <Divider className='my-3' />
                                        <Grid container>
                                            <Grid item xs={8}>Selected Slots</Grid>
                                            <Grid item xs={4}>{pricing[0].quantity}</Grid>
                                        </Grid>
                                        <Divider className='my-3' />
                                        <Grid container>
                                            <Grid item xs={8}>Monthly Subscription Total</Grid>
                                            <Grid item xs={4}>₹{total}</Grid>
                                        </Grid>
                                        <Divider className='my-3' />
                                    </Typography>

                                    {
                                        Array.isArray(payment_methods) && payment_methods.length > 0 ?
                                            <React.Fragment>
                                                <Typography variant='body1'>Saved Payment Methods</Typography>
                                                <List>
                                                    {
                                                        payment_methods.map(e => (
                                                            <ListItem dense button
                                                                onClick={() => {
                                                                    if (e.id !== payment_method) {
                                                                        return setPaymentMethod(e.id);
                                                                    }
                                                                    setPaymentMethod('');
                                                                }}
                                                                key={e.id}
                                                            >
                                                                <ListItemIcon>
                                                                    <Tooltip title={subscription?.default_payment_method === e.id ? `${e.card.brand} - Active Payment Method` : e.card.brand}>
                                                                        <Badge
                                                                            badgeContent={<LineIconLight name='checkmark-circle' style={{ color: blue['A700'] }} />}
                                                                            invisible={subscription?.default_payment_method !== e.id}
                                                                        >
                                                                            {CardComponent(e.card.brand)}
                                                                        </Badge>
                                                                    </Tooltip>
                                                                </ListItemIcon>
                                                                <ListItemText>
                                                                    **** {e.card.last4}
                                                                </ListItemText>
                                                                <ListItemSecondaryAction>
                                                                    <Checkbox color='primary'
                                                                        checked={payment_method === e.id}
                                                                        onClick={() => {
                                                                            if (e.id !== payment_method) {
                                                                                return setPaymentMethod(e.id);
                                                                            }
                                                                            setPaymentMethod('');
                                                                        }}
                                                                    />
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        ))
                                                    }
                                                </List>
                                            </React.Fragment>
                                            :
                                            null
                                    }

                                    <Grid container justify='center' className='mb-5 mt-3'>
                                        <Grid item xs={12} md={6} className='rounded'>
                                            <Typography variant='h6' className='mb-2 text-center'>Add Payment Method</Typography>
                                            <div className='border rounded-top p-3'>
                                                <CardNumberElement {...createOptions()} onFocus={() => setPaymentMethod('')} onChange={ev => setIsCardValid(ev.complete)} />
                                            </div>
                                            <Grid container className='border rounded-bottom'>
                                                <Grid xs={6} className='border-right p-3'>
                                                    <CardExpiryElement {...createOptions()} onFocus={() => setPaymentMethod('')} onChange={ev => setIsCardValid(ev.complete)} />
                                                </Grid>
                                                <Grid xs={6} className='border-left p-3'>
                                                    <CardCvcElement {...createOptions()} onFocus={() => setPaymentMethod('')} onChange={ev => setIsCardValid(ev.complete)} />
                                                </Grid>
                                            </Grid>
                                            {err && <p className='text-danger mt-2 mb-0'>{err}</p>}
                                        </Grid>
                                    </Grid>


                                    <div className='d-flex'>
                                        <Button
                                            onClick={() => setActiveStep(0)}
                                            variant='contained' color='primary'
                                            disabled={subscribing} className='mr-3 ml-auto'
                                            startIcon={subscribing ? <CircularProgress size={20} /> : null}
                                        >
                                            Back
                                        </Button>

                                        <Button variant='contained' color='primary'
                                            onClick={() => startSubscription()}
                                            disabled={subscribing}
                                            startIcon={subscribing ? <CircularProgress size={20} /> : null}
                                        >
                                            Start Subscription
                                        </Button>
                                    </div>

                                </React.Fragment>
                            }

                            {
                                activeStep === 1 && subscription &&
                                <React.Fragment>

                                    <div className='mt-4 mb-4'>
                                        <Typography variant='h6' component='div'>
                                            <Divider className='my-3' />
                                            <Grid container>
                                                <Grid item xs={8}>Monthly Subscription Total</Grid>
                                                <Grid item xs={4}>₹{total}</Grid>
                                            </Grid>
                                            <Divider className='my-3' />
                                            <Grid container>
                                                <Grid item xs={8}>Payable Now</Grid>
                                                <Grid item xs={4}>₹{to_be_paid / 100}</Grid>
                                            </Grid>
                                            <Divider className='my-3' />
                                        </Typography>
                                        {err && <p className='text-danger mt-2 mb-0'>{err}</p>}
                                    </div>

                                    <div className='d-flex'>
                                        <Button
                                            onClick={() => setActiveStep(0)}
                                            variant='contained' color='primary'
                                            disabled={subscribing} className='mr-3 ml-auto'
                                            startIcon={subscribing ? <CircularProgress size={20} /> : null}
                                        >
                                            Back
                                        </Button>

                                        <Button variant='contained' color='primary'
                                            onClick={() => updateSubscription()}
                                            disabled={subscribing}
                                            startIcon={subscribing ? <CircularProgress size={20} /> : null}
                                        >
                                            Update Subscription
                                        </Button>
                                    </div>

                                </React.Fragment>
                            }

                            {
                                activeStep === 2 &&
                                <div className='text-center p-3'>
                                    <LineIconLight name='checkmark' size='lg' style={{ color: '#69ff69' }} />
                                    <h6>Payment Successful</h6>
                                    <Button variant='contained' color='primary' className='mt-2' onClick={() => history.push('/login')}>Add Listings</Button>
                                </div>
                            }

                            {
                                activeStep === 0 &&
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                    </Grid>
                                </Grid>
                            }

                        </div>
                    </React.Fragment>
            }
        </Paper >
    )
}