import { Typography, Paper, CircularProgress, Divider, Grid, List, ListItem, ListItemAvatar, Avatar, ListItemText } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars';
import moment from "moment";

import QuestionsView from "./view";
import LocationFilter from '../../resources/locationFilter'
import { useLocation } from "react-router-dom";
import { _fetch } from "../../netReq";

function giveDateTime(givenDTFormat) {
    return moment(givenDTFormat).format('ll')
}

export default function Questions() {

    const [loading, setLoading] = useState(false);
    const info = useSelector(state => state.userReducer);
    const [next, setNext] = useState('');
    const [selectedLocation, setSelectedLocations] = useState('');
    const [selected, setSelected] = useState(0);
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    const [loadingMore, setLoadingMore] = useState(false);

    const accessToken = info.accessToken;
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        if (Array.isArray(info.locations) && info.locations.length > 0) {
            if (id) {
                for (let i = 0; i < info.locations.length; i++)
                    if (info.locations[i].locationId === id) {
                        setSelectedLocations(info.locations[i])
                        break;
                    }
            }
            else if (info.locations.length > 0)
                setSelectedLocations(info.locations[0])
        }
    }, [info.locations, id]);

    function fetchMore() {

        if(!selectedLocation) return setQuestions([]);
        
        setLoadingMore(true);

        if (!loadingMore && next)
            _fetch(`${process.env.REACT_APP_GMB_QANDA_URL}${selectedLocation?.name}/questions?pageToken=${next}`, { token: info.accessToken })
                .then(
                    (result) => {
                        setLoading(false)
                        setLoadingMore(false)
                        if (result.questions) {
                            setQuestions([...questions, ...result.questions])
                        }
                        if (result.nextPageToken)
                            setNext(result.nextPageToken)
                        else
                            setNext('');
                    },
                    (error) => {
                        process.env.NODE_ENV === 'development' && console.log(error)
                    }
                )
                .catch(error => {
                    process.env.NODE_ENV === 'development' && console.log(error)
                })
                .finally(() => {
                    setLoadingMore(false);
                });
    }

    const fetchQuestions = useCallback(() => {

        if(!selectedLocation) return setQuestions([]);

        if (accessToken && selectedLocation) {
            setLoading(true);
            fetch(`${process.env.REACT_APP_GMB_QANDA_URL}${selectedLocation?.name}/questions`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setQuestions(result.questions);
                        if (result.nextPageToken)
                            setNext(result.nextPageToken)
                        else
                            setNext('');
                    },
                    (error) => {
                        process.env.NODE_ENV === 'development' && console.log(error)
                    }
                )
                .catch(error => {
                    process.env.NODE_ENV === 'development' && console.log(error)
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [accessToken, selectedLocation]);

    useEffect(() => {
        fetchQuestions();
    }, [fetchQuestions]);

    return (
        <Paper className="flex-fill mb-3 p-3 d-flex flex-column" elevation={3} style={{ minHeight: 600 }}>
            <div className='d-flex align-items-center px-3'>
                <LocationFilter
                    multiple={false}
                    selectedLocs={selectedLocation}
                    setSelectedLocations={setSelectedLocations}
                    placeholder='Select a location'
                />
            </div>

            <Divider className='my-3 mx-3' />

            <div className='px-3 flex-fill'>
                {
                    loading
                        ?
                        <div className='h-100 w-100 d-flex'>
                            <CircularProgress size={75} className='m-auto' />
                        </div>
                        :
                        <Grid container>

                            <Grid item xs={6} className='border-right'>
                                <Scrollbars
                                    autoHide style={{ height: '70vh' }}
                                    onScrollFrame={(val) => {
                                        if (val.top >= 1 && next) {
                                            fetchMore();
                                        }
                                    }}
                                >
                                    {
                                        info.loadingLocations || info.statsLoading
                                            ?
                                            <div className='h-100 w-100 d-flex'>
                                                <CircularProgress size={75} className='m-auto' />
                                            </div>
                                            :
                                            questions && Array.isArray(questions) && questions.length
                                                ?
                                                <React.Fragment>
                                                    <List dense className='w-100 h-100 pr-2' disablePadding style={{marginBottom: questions.length >= 10 ? '50vh' : 0}}>
                                                        {
                                                            questions.map((e, i) => (
                                                                <React.Fragment key={i}>
                                                                    <ListItem
                                                                        className="pointer" selected={i === selected}
                                                                        onClick={() => setSelected(i)} button>
                                                                        <ListItemAvatar>
                                                                            <Avatar alt={e.author.displayName} src={e.author.profilePhotoUrl} />
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={
                                                                                <div className='d-flex justify-content-between'>
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        color="textPrimary"
                                                                                        className='d-inline'
                                                                                    >
                                                                                        {e.author.displayName}
                                                                                    </Typography>
                                                                                    <Typography variant='caption' component='div'>
                                                                                        {giveDateTime(e.updateTime)}
                                                                                    </Typography>
                                                                                </div>
                                                                            }
                                                                            secondary={
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    color="textPrimary"
                                                                                    className='d-inline'
                                                                                >
                                                                                    {e.text}
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                    { i !== (questions.length - 1) && <Divider />}
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </List>
                                                    {
                                                        loadingMore &&
                                                        <div className='w-100 d-flex'>
                                                            <div className="loader loaders mx-auto">
                                                                <div className='loader-inner ball-beat'>
                                                                    <div></div>
                                                                    <div ></div>
                                                                    <div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                                :
                                                <div className='h-100 w-100 d-flex'>
                                                    <Typography variant='h6' className='m-auto text-center'>
                                                        No Question found
                                                    </Typography>
                                                </div>
                                    }
                                </Scrollbars>
                            </Grid>

                            <Grid item xs={6} className='border-left'>
                                <Scrollbars autoHide style={{ height: '70vh' }}>
                                    {
                                        info.loadingLocations || info.statsLoading
                                            ?
                                            <div className='h-100 w-100 d-flex'>
                                                <CircularProgress size={75} className='m-auto' />
                                            </div>
                                            :
                                            questions && Array.isArray(questions) && questions.length > selected
                                                ?
                                                <QuestionsView
                                                    name={questions[selected].name}
                                                    question={questions[selected].text}
                                                    answer={questions[selected].topAnswers}
                                                />
                                                :
                                                <div className='h-100 w-100 d-flex'>
                                                    <Typography variant='h6' className='m-auto text-center'>
                                                        Please Select a Question
                                                    </Typography>
                                                </div>
                                    }
                                </Scrollbars>
                            </Grid>

                        </Grid>
                }
            </div>

        </Paper >

    );
}