import { Typography, Paper, List, CircularProgress, Grid, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars';
import { Rating } from "@material-ui/lab";
import moment from "moment";

import ReviewView from "./view";
import LocationFilter from '../../resources/locationFilter'
import { _fetch } from "../../netReq";
import LineIconLight from "../../react-lineicons-light";

function getStarNum(star) {
    switch (star) {
        case 'ONE': return 1;
        case 'TWO': return 2;
        case 'THREE': return 3;
        case 'FOUR': return 4;
        case 'FIVE': return 5;
        default: return;
    }
}

function giveDateTime(givenDTFormat) {
    return moment(givenDTFormat).format('ll')
}

export default function Reviews() {

    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const red = useSelector(state => state.userReducer);
    const [locForNoti, setLocForNoti] = useState({});
    const [next, setNext] = useState('');
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selected, setSelected] = useState(0);
    const [locNames, setLocNames] = useState([]);

    const [review, setReview] = useState([]);

    useEffect(() => {
        if (red.locations && Array.isArray(red.locations) && red.locations.length > 0) {
            let locNames = [], names = {};
            red.locations.forEach(e => {
                names[e.name] = `${e.locationName} ${e.nickName ? `(${e.nickName})` : ''}`;
                locNames.push(e.name);
            });
            setLocForNoti(names);
            setLocNames(locNames);
            setSelectedLocations(red.locations);
        }
    }, [red.locations]);

    function fetchMore() {

        if (!Array.isArray(selectedLocations) || !selectedLocations.length)
            return setReview([]);

        let locNmArr = selectedLocations.map((e) => e.name);

        let body = {
            locationNames: locNmArr,
            pageSize: 10,
            pageToken: next
        };

        setLoadingMore(true);

        if (!loadingMore && next)
            _fetch(`${process.env.REACT_APP_GMB_URL}accounts/${red.account_id}/locations:batchGetReviews`,
                {
                    method: 'POST', token: red.accessToken, body: body
                })
                .then(
                    (result) => {
                        setLoading(false)
                        setLoadingMore(false)
                        if (result.locationReviews)
                            setReview(val => [...val, ...result.locationReviews])
                        if (result.nextPageToken)
                            setNext(result.nextPageToken)
                        else
                            setNext('');
                    },
                    (error) => {
                        process.env.NODE_ENV === 'development' && console.log(error)
                    }
                )
                .catch(error => {
                    process.env.NODE_ENV === 'development' && console.log(error)
                })
                .finally(() => {
                    setLoadingMore(false);
                });
    }

    useEffect(() => {
        if (red.accessToken && red.account_id && Array.isArray(locNames) && locNames.length > 0) {

            if (!Array.isArray(selectedLocations) || !selectedLocations.length)
                return setReview([]);

            let locNmArr = selectedLocations.map((e) => `accounts/${red.account_id} /${e.name}`);

            let body = {
                locationNames: locNmArr,
                pageSize: 20
            }

            setLoading(true);

            _fetch(`${process.env.REACT_APP_GMB_URL}accounts/${red.account_id}/locations:batchGetReviews`,
                {
                    method: 'POST', token: red.accessToken, body: body
                })
                .then(
                    (result) => {
                        setLoading(false);
                        setReview(result.locationReviews);
                        if (result.nextPageToken)
                            setNext(result.nextPageToken)
                        else
                            setNext('');
                    },
                    (error) => {
                        process.env.NODE_ENV === 'development' && console.log(error)
                    }
                )
                .catch(error => {
                    process.env.NODE_ENV === 'development' && console.log(error)
                });
        }
    }, [red.accessToken, red.account_id, locNames, selectedLocations]);

    return (
        <Paper className="flex-fill mb-3 p-3 d-flex flex-column" elevation={3} style={{ minHeight: 600 }}>

            <div className='d-flex align-items-center px-3'>

                <LocationFilter
                    selectedLocs={selectedLocations}
                    setSelectedLocations={setSelectedLocations}
                />

            </div>

            <Divider className='my-3 mx-3' />

            <div className='px-3 flex-fill'>
                {
                    loading
                        ?
                        <div className='h-100 w-100 d-flex'>
                            <CircularProgress size={75} className='m-auto' />
                        </div>
                        :
                        <Grid container>
                            <Grid item xs={6} className='border-right'>
                                <Scrollbars
                                    autoHide style={{ height: '70vh' }}
                                    onScrollFrame={(val) => {
                                        if (val.top >= 1 && next) {
                                            fetchMore();
                                        }
                                    }}
                                >
                                    {
                                        red.loadingLocations || red.statsLoading
                                            ?
                                            <div className='h-100 w-100 d-flex'>
                                                <CircularProgress size={75} className='m-auto' />
                                            </div>
                                            :
                                            review
                                                ?
                                                <React.Fragment>
                                                    <List dense className='w-100 pr-2' disablePadding>
                                                        {
                                                            Array.isArray(review) && review.map((e, i) => (
                                                                <React.Fragment key={i}>
                                                                    <ListItem
                                                                        className="pointer" selected={i === selected}
                                                                        onClick={() => setSelected(i)} button>
                                                                        <ListItemAvatar>
                                                                            <Avatar alt={e.review.reviewer.displayName} src={e.review.reviewer.profilePhotoUrl} />
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={
                                                                                <div className='d-flex justify-content-between'>
                                                                                    <Typography
                                                                                        component="span"
                                                                                        variant="body2"
                                                                                        color="textPrimary"
                                                                                        className='d-inline'
                                                                                    >
                                                                                        {e.review.reviewer.displayName}
                                                                                    </Typography>

                                                                                    <Rating
                                                                                        icon={<LineIconLight name='star-fill' style={{ color: 'gold' }} size='sm' />}
                                                                                        emptyIcon={<LineIconLight name='star-fill' style={{ color: 'lightgrey' }} size='sm' />}
                                                                                        value={getStarNum(e.review.starRating)} readOnly
                                                                                    />

                                                                                </div>
                                                                            }
                                                                            secondary={
                                                                                <span className='d-flex justify-content-between mt-1'>
                                                                                    <Typography variant='caption' component='div'>
                                                                                        {giveDateTime(e.review.updateTime)}
                                                                                    </Typography>

                                                                                    <Typography variant='caption' component='div'>
                                                                                        {locForNoti[e.name]}
                                                                                    </Typography>
                                                                                </span>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                    {i !== (review.length - 1) && <Divider />}
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </List>
                                                    {
                                                        loadingMore &&
                                                        <div className='w-100 d-flex'>
                                                            <div className="loader loaders mx-auto">
                                                                <div className='loader-inner ball-beat'>
                                                                    <div></div>
                                                                    <div ></div>
                                                                    <div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                                :
                                                <div className='h-100 w-100 d-flex'>
                                                    <Typography variant='h6' className='m-auto'>
                                                        No reviews found
                                                    </Typography>
                                                </div>
                                    }
                                </Scrollbars>
                            </Grid>

                            <Grid item xs={6} className='border-left'>
                                <Scrollbars autoHide style={{ height: '70vh' }}>
                                    {
                                        red.loadingLocations || red.statsLoading
                                            ?
                                            <div className='h-100 w-100 d-flex'>
                                                <CircularProgress size={75} className='m-auto' />
                                            </div>
                                            :
                                            review && Array.isArray(review) && review.length > selected
                                                ?
                                                <ReviewView
                                                    name={review[selected].review.name}
                                                    comment={review[selected].review.comment}
                                                    replyComment={review[selected].review.reviewReply?.comment}
                                                />
                                                :
                                                <div className='h-100 w-100 d-flex'>
                                                    <Typography variant='h6' className='m-auto'>
                                                        Please Select a Review
                                                    </Typography>
                                                </div>
                                    }
                                </Scrollbars>
                            </Grid>
                        </Grid>
                }
            </div>

        </Paper>

    );
}