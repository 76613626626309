import { IoWarningOutline } from 'react-icons/io5';
import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import {
    FormControl, Grid, InputLabel, MenuItem, Select, TextField, Paper, Button, GridList, Divider,
    GridListTile, Card, Popper, Dialog, DialogTitle, DialogActions, IconButton, CircularProgress
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { toast } from 'react-toastify';
import { _fetch } from '../../netReq';
import { useHistory, useLocation } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import update from 'immutability-helper';
import { GoAlert } from 'react-icons/go'
import { MdLocalOffer, MdEvent, MdNewReleases } from 'react-icons/md'
import validator from 'validator'
import { primaryColor } from '../../theme';
import LineIconRegular from '../../react-lineicons-regular';
import LocationFilter from '../../resources/locationFilter'
import { useSelector } from 'react-redux';


let date = new Date();

const postTopicType = [
    { value: 'STANDARD', key: 'STANDARD', text: 'What\'s New', Icon: MdNewReleases },
    { value: 'EVENT', key: 'EVENT', text: 'Event', Icon: MdEvent },
    { value: 'OFFER', key: 'OFFER', text: 'Offer', Icon: MdLocalOffer },
    { value: 'ALERT', key: 'ALERT', text: 'Alert', Icon: GoAlert }
];

const postActionType = [
    { value: 'ACTION_TYPE_UNSPECIFIED', key: 'ACTION_TYPE_UNSPECIFIED', text: 'None' },
    { value: 'BOOK', key: 'BOOK', text: 'Book' },
    { value: 'ORDER', key: 'ORDER', text: 'Order' },
    { value: 'SHOP', key: 'SHOP', text: 'Shop' },
    { value: 'LEARN_MORE', key: 'LEARN_MORE', text: 'Learn More' },
    { value: 'SIGN_UP', key: 'SIGN_UP', text: 'Sign Up' },
    { value: 'CALL', key: 'CALL', text: 'Call' }
];

const alertType = [
    { value: 'COVID_19', key: 'COVID_19', text: 'covid-19' },
];

const initialState = {
    languageCode: 'en',
    summary: '',
    callToAction: {
        actionType: 'ACTION_TYPE_UNSPECIFIED',
        url: ''
    },
    event: {
        title: '',
        schedule: {
            startDate: {
                year: date.getFullYear(),
                month: date.getMonth(),
                day: date.getDate()
            },
            startTime: {
                hours: 0,
                minutes: 0,
                seconds: 0,
                nanos: 0
            },
            endDate: {
                year: date.getFullYear(),
                month: date.getMonth(),
                day: date.getDate() + 1
            },
            endTime: {
                hours: 0,
                minutes: 0,
                seconds: 0,
                nanos: 0
            }
        }
    },
    topicType: 'STANDARD',
    alertType: 'COVID_19',
    offer: {
        couponCode: '',
        redeemOnlineUrl: '',
        termsConditions: ''
    },
    postDate: moment().add(3600 * 1000 * 24),
    _id: '',
    media_keys: []
};

export default function Scheduler() {

    const [data, setData] = useState(initialState);
    const [files, setFiles] = useState([]);
    const [schedulePost, setSchedulePost] = useState(false);
    const [openDTPicker, setOpenDTPicker] = useState(false);
    const [warning, setWarning] = useState(false);
    const [loading, setLoading] = useState(false);
    const [posting, setPosting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [errored_locations, setErroredLocations] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [status, setStatus] = useState('');
    const publishBtnRef = useRef(null);

    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    const color = 'primary';
    const mixpanel = useMixpanel();
    const timer = useRef(null);
    const { locations, statsLoading } = useSelector(st => st.userReducer);
    let history = useHistory();

    useEffect(() => {
        if (id) {
            let path = `${process.env.REACT_APP_API_URL}/scheduler?id=${id}`;

            _fetch(path)
                .then(res => {
                    if (res.status === 200) {
                        setData((prev_value) => ({ ...prev_value, ...res.response.result[0] }));
                        setFiles(res.response.result[0].media.map(e => ({ sourceUrl: e.sourceUrl, type: e.media_format })));
                    }
                })
                .catch(err => {
                    process.env.NODE_ENV === 'development' && console.log(err);
                    setLoading(false);
                })
        }
    }, [id]);

    useEffect(() => {
        if (Array.isArray(data.locationName) && Array.isArray(locations)) {
            setSelectedLocations(locations.filter(e => data.locationName.includes(e.name)))
        }
    }, [locations, data.locationName]);

    const onChange = (ev) => {
        setData({
            ...data,
            [ev.target.name]: ev.target.value
        });
    }

    const onTypeChange = (evt) => {
        setData({ ...data, callToAction: { ...data.callToAction, [evt.target.name]: evt.target.value } })
    }

    const onEventStartChange = (dt) => {
        let date = moment(dt);
        setData({
            ...data,
            event: {
                schedule: {
                    ...data.event.schedule,
                    startDate: {
                        ...data.event.schedule.startDate,
                        year: date.year(),
                        month: date.month() + 1,
                        day: date.date()
                    },
                    startTime: {
                        ...data.event.schedule.startTime,
                        hours: date.hours(),
                        minutes: date.minutes()
                    }
                }
            }
        })
    }

    const onEventEndChange = (dt) => {
        let date = moment(dt);
        setData({
            ...data,
            event: {
                schedule: {
                    ...data.event.schedule,
                    endDate: {
                        ...data.event.schedule.endDate,
                        year: date.year(),
                        month: date.month() + 1,
                        day: date.date()
                    },
                    endTime: {
                        ...data.event.schedule.endTime,
                        hours: date.hours(),
                        minutes: date.minutes()
                    }
                }
            }
        })

    }

    const onOfferChange = (ev) => {
        setData({
            ...data,
            offer: {
                ...data.offer,
                [ev.target.name]: ev.target.value
            }
        });
    }

    const onTopicChange = (ev) => () => {
        process.env.NODE_ENV === 'development' && console.log(ev)
        setData({
            ...data,
            topicType: ev
        });
    }

    function generatePostBody(post) {
        const {
            languageCode, summary, callToAction, event, media, topicType, offer, alertType
        } = post;

        let body = { languageCode };

        if (topicType !== 'LOCAL_POST_TOPIC_TYPE_UNSPECIFIED') body.topicType = topicType;
        if (topicType !== 'OFFER' && callToAction && callToAction.actionType && callToAction.actionType !== 'ACTION_TYPE_UNSPECIFIED') {
            body.callToAction = {
                actionType: callToAction.actionType
            };
            if (callToAction.actionType !== 'CALL') body.callToAction.url = callToAction.url;
        }
        if (topicType === 'OFFER') body.offer = offer;
        if ((topicType === 'OFFER' || topicType === 'EVENT') && event.title && event.schedule) body.event = event;
        if (topicType === 'ALERT') body.alertType = alertType;
        if (summary) body.summary = summary;
        if (topicType !== 'ALERT' && Array.isArray(media) && media.length > 0) body.media = media;

        return body;
    }

    async function uploadMedia(id) {

        let arr = [];
        for (let i = 0; i < files.length; i++) {

            // if file is already uploaded add it to array
            if (files[i].sourceUrl) {
                arr.push({ ...files[i], media_format: files[i].type });
                continue;
            }

            // generate file name
            let ext = files[i].file.name.split('.');
            if (ext.length > 1) ext = ext[ext.length - 1];
            else ext = files[i].type === 'PHOTO' ? '.png' : '.mp4';
            let date = new Date();
            let name = `${date.valueOf()}.${ext}`;

            // get signed policy to upload file
            let res = await _fetch(`${process.env.REACT_APP_API_URL}/media?type=${files[i].type}&post_id=${id}`);

            // upload file
            const formData = new FormData();
            formData.append('key', `gmb_post/${id}/${name}`);
            formData.append('acl', 'public-read');
            formData.append('Content-Type', files[i].file.type);
            for (let a in res.response) {
                formData.append(a, res.response[a]);
            }
            formData.append('file', files[i].file);
            res = await fetch(`${process.env.REACT_APP_AWS_BUCKET}`, {
                method: 'POST',
                body: formData
            });

            // add file to array
            arr.push({
                sourceUrl: `${process.env.REACT_APP_AWS_BUCKET}gmb_post/${id}/${name}`,
                media_format: files[i].type
            });

        }

        return arr;
    }

    function validateBody(body) {
        let msg = '';
        if (
            body.topicType !== 'OFFER' && body.callToAction &&
            body.callToAction.actionType !== 'ACTION_TYPE_UNSPECIFIED' && body.callToAction.actionType !== 'CALL' && !validator.isURL(body.callToAction.url)
        )
            msg = 'Please enter a valid URL';
        else if (body.topicType === 'ALERT' && !body.summary)
            msg = 'Description is required for Alert Post';
        else if (body.topicType === 'STANDARD' && !body.summary && !files.length)
            msg = '"What\'s New" post must have at least a media or summary.';
        else if ((body.topicType === 'EVENT' || body.topicType === 'OFFER') && !body.event.title)
            msg = `${body.topicType === 'EVENT' ? 'Event' : 'Offer'} title is required`;
        else if (body.callToAction && body.callToAction.actionType !== 'CALL' && !body.callToAction.url)
            msg = 'Call to action url is required';

        if (msg) toast.error(msg);
        return msg;
    }

    function handleSuccess(response) {
        if (Array.isArray(response.errored_locations) && response.errored_locations.length) {
            setErroredLocations(response.errored_locations);
        }
        else {
            toast.success('Post scheduled successfully');
            setData(initialState);
            setFiles([]);
            setSelectedLocations([]);
        }
    }

    const onPublish = async () => {
        mixpanel.track('button click', { schedulePost, action: 'schedule_post' });

        setOpenDTPicker(false);
        setAnchorEl(null);
        setPosting(schedulePost ? 'SCHEDULED' : 'POST_NOW');

        try {

            const { postDate, _id } = data;

            const _locs = selectedLocations;

            const locationName = _locs.map(e => e.name);

            if (!Array.isArray(locationName) || !locationName.length)
                return toast.error('Please select locations');

            let body = generatePostBody(data);

            if (validateBody(body, locationName)) return;

            body.locationName = locationName;

            // 1. all posts are posted from backend only
            // 2. if post already exists we first upload media with gmb_post/${post_id} as the prefix
            // 3. but if we are creating a new post then we first schedule post and then upload media
            // 4. if post is to be posted now, we save it as draft by setting status as DRAFT then upload media then update it with 
            //    status POST_NOW otherwise we update post with status SCHEDULED
            if (_id) {

                body._id = _id;
                body.status = schedulePost ? 'SCHEDULED' : 'POST_NOW';

                if (body.topicType !== 'ALERT') {
                    body.media = await uploadMedia(_id);
                }
                if (schedulePost)
                    body.postDate = postDate;


                let res = await _fetch(`${process.env.REACT_APP_API_URL}/scheduler`, { method: 'PATCH', body });
                if (res.status === 200) {
                    handleSuccess(res.response);

                    if (id)
                        history.push('/scheduled');
                } else {
                    toast.error(res.response);
                }
                setPosting(false);

            } else {

                body.status = schedulePost ? 'SCHEDULED' : 'POST_NOW';

                if (files.length > 0 && body.topicType !== 'ALERT') {
                    body.status = 'DRAFT';
                }

                if (schedulePost)
                    body.postDate = postDate;

                let res = await _fetch(`${process.env.REACT_APP_API_URL}/scheduler`, { method: 'POST', body: body });

                if (res.success) {
                    if (body.status === 'DRAFT') {

                        let { id } = res.response;
                        setData(prevData => ({ ...prevData, _id: id }));
                        body.media = await uploadMedia(id);
                        setFiles(body.media.map(e => ({ sourceUrl: e.sourceUrl, type: e.media_format })));
                        body.status = schedulePost ? 'SCHEDULED' : 'POST_NOW';
                        body._id = id;
                        res = await _fetch(`${process.env.REACT_APP_API_URL}/scheduler`, { method: 'PATCH', body });
                        if (res.status === 200) {
                            handleSuccess(res.response);
                        } else {
                            toast.error(res.response);
                        }
                    } else {
                        handleSuccess(res.response);
                        if (id)
                            history.push('/scheduled');
                    }
                }
                else
                    toast.error(res.response);
            }

        } catch (err) {
            process.env.NODE_ENV === 'development' && console.log(err);
            toast.error('Some error occurred, please try again');
        } finally {
            setPosting(false);
        }
    }

    const onSubmit = (evt) => {
        evt.preventDefault();

        setErroredLocations([]);

        if (schedulePost)
            setOpenDTPicker(true);
        else {
            setStatus('PUBLISH_NOW');
            setAnchorEl(publishBtnRef.current);
        }
    }

    function loadFile(file) {

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e => {

                if (file.type.startsWith('video/')) {
                    return resolve(e.target.result);
                }

                let img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    if (img.height > 250 && img.width > 250) {
                        return resolve(e.target.result);
                    }
                    else reject('invalid size');
                }
            });
            reader.readAsDataURL(file);
        });
    }

    const onFileChange = async (evt) => {
        setLoading(true);

        // uncomment below line when google starts supporting multiple image media
        // let arr1 = files.slice(0);
        let arr1 = [];
        const temp = evt.target.files;

        // push raw photo/video file into arr1
        for (let i = 0; i < temp.length; i++) {
            const file = temp[i];
            if (!(file.type.startsWith('image/') && file.size >= 10240)) { continue }
            arr1.push({ file, type: file.type.startsWith('image/') ? 'PHOTO' : 'VIDEO' });
        }

        // if file added is not video or image
        if (arr1.length === files.length) {
            setLoading(false);
            toast.info('Only images are allowed. Images should be at least 250px x 250px in dimension and 10kb in size.');
            return;
        }

        for (let i = 0; i < arr1.length; i++) {

            if (arr1[i].sourceUrl) continue;

            const file = arr1[i].file;
            try {
                let preview = await loadFile(file);
                arr1[i].preview = preview;
            } catch (err) {
                toast.info('Only images and videos are allowed. Images should be at least 250px x 250px in dimension and 10kb in size.');
                arr1.splice(i, 1);
                i -= 1;
            }
        }
        setFiles(arr1);
        setLoading(false);
    }

    function deleteScheduledPost() {
        setAnchorEl(null);
        let path = `${process.env.REACT_APP_API_URL}/scheduler?id=${id}`;
        setDeleting(true);
        _fetch(path, { method: 'DELETE' })
            .then(res => {
                if (res.status === 200) {
                    toast.success("Post deleted successfully");
                    return history.push('/scheduled');
                }
                toast.error(res.response || 'Some error occurred while removing post, please try again');
            })
            .catch(err => {
                process.env.NODE_ENV === 'development' && console.log(err);
                setLoading(false);
                toast.error(err.message || 'Some error occurred while removing post, please try again');
            });
        setDeleting(false);
    }

    if (statsLoading) {
        return (
            <Paper className='flex-fill mb-3 p-3 d-flex flex-column' elevation={3}>
                <CircularProgress size={75} className='m-auto' />
            </Paper >
        )
    }

    return (
        <Paper className='flex-fill mb-3 p-3 d-flex flex-column' elevation={3}>

            <form onSubmit={onSubmit}>

                <div className='d-flex align-items-center px-3'>
                    <LocationFilter
                        selectedLocs={selectedLocations}
                        setSelectedLocations={setSelectedLocations}
                        placeholder='Select locations for post'
                        view='SCHEDULER'
                        multiple={true}
                    />

                </div>

                <Divider className='my-3 mx-3' />

                <div className='px-3'>
                    {
                        postTopicType.map((e, i) => (
                            <Button
                                style={{ borderRadius: '5px', width: '23%' }} className='py-2 mx-2 '
                                variant={data.topicType === e.value ? 'contained' : 'text'} color={data.topicType === e.value ? 'primary' : 'inherit'}
                                onClick={onTopicChange(e.value)} key={i}
                                startIcon={<e.Icon />}
                            >
                                {e.text}
                            </Button>
                        ))
                    }
                </div>

                <Divider className='my-3 mx-3' />

                {/* -----media info------ */}
                {
                    data.topicType !== 'ALERT' &&
                    <div className='px-3 my-3'>
                        {
                            loading ?
                                <CircularProgress size={75} className='m-auto' />
                                :
                                <GridList cellHeight={170} cols={files.length ? 3.5 : 1} style={{ flexWrap: 'nowrap' }}>

                                    {
                                        files.length === 0 ?
                                            <GridListTile>
                                                <label htmlFor='addMedia' className='h-100 w-100 d-flex'
                                                    style={{
                                                        border: `3px dashed ${primaryColor}`, borderRadius: '10px', cursor: 'pointer',
                                                        backgroundColor: `${primaryColor}10`
                                                    }}>
                                                    <h6 className='m-auto' style={{ color: primaryColor }}>Add a photo</h6>
                                                </label>
                                                <input type='file' accept='image/*' style={{ zIndex: 10, opacity: 0 }} id='addMedia'
                                                    onChange={onFileChange} hidden />

                                            </GridListTile>
                                            :
                                            null
                                    }
                                    {
                                        files.map((e, i) =>
                                            <div className='d-flex position-relative m-auto mx-1' key={i}>
                                                {
                                                    e.type === 'PHOTO' ?
                                                        <img height={170} src={e.sourceUrl ? e.sourceUrl : e.preview} alt='media item' style={{ borderRadius: '10px' }} />
                                                        :
                                                        <video height={170} autoPlay={false} muted controls={true} className='m-auto media' src={e.sourceUrl ? e.sourceUrl : e.preview} alt='media item$' />
                                                }
                                                <IconButton
                                                    onClick={() => {
                                                        setFiles((st) => update(st, { $splice: [[i, 1]] }))
                                                    }}
                                                    className='position-absolute'
                                                    style={{ backgroundColor: '#00000080', right: 10, top: 10 }}
                                                    size='small' color='primary'
                                                >
                                                    <LineIconRegular name='close' size='xs' />
                                                </IconButton>
                                            </div>
                                        )
                                    }
                                </GridList>
                        }
                    </div>
                }

                <Grid container className="px-3" spacing={3}>

                    {/* -----event info---- */}

                    {
                        (data.topicType === 'OFFER' || data.topicType === 'EVENT') &&
                        <React.Fragment>
                            {/* <Grid item xs={12}>
                            <h6>Event Info</h6>
                        </Grid> */}
                            <Grid item xs={12}>
                                <TextField label={`${data.topicType === 'OFFER' ? 'Offer' : 'Event'} Title`} name='title' variant="outlined"
                                    className='w-100' color={color} value={data.event.title} required={true}
                                    onChange={evt => setData({ ...data, event: { ...data.event, title: evt.target.value } })}
                                />
                            </Grid>

                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Grid item xs={6}>
                                    <DateTimePicker className='w-100' label="Start date and time" inputVariant="outlined"
                                        value={moment({ ...data.event.schedule.startDate, ...data.event.schedule.startTime })}
                                        onChange={onEventStartChange} color={color}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DateTimePicker className='w-100' label="End date and time" inputVariant="outlined"
                                        value={moment({ ...data.event.schedule.endDate, ...data.event.schedule.endTime })}
                                        onChange={onEventEndChange} color={color}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </React.Fragment>
                    }

                    {/* -----offer info------ */}

                    {
                        data.topicType === 'OFFER' &&
                        <React.Fragment>

                            {/* <Grid item xs={12}>
                            <h6>Offer info</h6>
                        </Grid> */}
                            <Grid item xs={6}>
                                <TextField label="Coupon Code" name='couponCode' variant="outlined"
                                    className='w-100' color={color} onChange={onOfferChange} value={data.offer.couponCode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Offer online redeem url" name='redeemOnlineUrl' variant="outlined"
                                    className='w-100' color={color} onChange={onOfferChange} value={data.offer.redeemOnlineUrl}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField label="Terms And Conditions" name='termsConditions' variant="outlined" rows={3}
                                    className='w-100' onChange={onOfferChange} color={color} multiline value={data.offer.termsConditions}
                                />
                            </Grid>

                        </React.Fragment>
                    }
                    {/* ------description------- */}

                    <Grid item xs={12}>
                        <TextField label="Description" name='summary' className='w-100' color={color} value={data.summary}
                            variant='outlined' onChange={onChange} multiline rows={2} required={data.topicType === 'ALERT'}
                            onKeyUp={() => {
                                if (timer.current)
                                    clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    let str = data.summary;
                                    let regex = /([0|+[0-9]{1,5})?[ ]{0,1}([7-9][0-9]{9})/gm;
                                    if (regex.test(str)) setWarning(true);
                                    else setWarning(false);
                                }, 2000);
                            }}
                        />
                        {warning && <p className='my-2 warn'> <IoWarningOutline size={15} /> Using phone number is a violation of Google My Business <a href='https://support.google.com/business/answer/7213077?hl=en' className='warn'><u>post content policy</u></a>.</p>}
                    </Grid>

                    {/* -----call to action info---- */}

                    {
                        data.topicType !== 'OFFER' &&
                        <React.Fragment>
                            <Grid item xs={6} >
                                <FormControl className='w-100' variant="outlined" color={color}>
                                    <InputLabel id='actionType' color={color}> Call To Action</InputLabel>
                                    <Select labelId="actionType" name='actionType' onChange={onTypeChange}
                                        label='Call To Action' defaultValue='ACTION_TYPE_UNSPECIFIED' value={data.callToAction.actionType}
                                    >
                                        {postActionType.map(e => <MenuItem key={e.key} value={e.value}>{e.text}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                data.callToAction.actionType !== 'ACTION_TYPE_UNSPECIFIED' &&
                                <Grid item xs={6}>
                                    {
                                        data.callToAction.actionType === 'CALL' ?
                                            <TextField variant="outlined"
                                                className='w-100' color={color}
                                                value='Verified Phone No. will be added'
                                                disabled
                                            />
                                            :
                                            <TextField label="Action URL" name='url' variant="outlined"
                                                value={data.callToAction.url}
                                                className='w-100' color={color} onChange={onTypeChange} required
                                            />
                                    }
                                </Grid>
                            }
                        </React.Fragment>
                    }
                    {/* -----------ALERT------------- */}
                    {
                        data.topicType === 'ALERT' &&
                        <Grid item xs={6} >
                            <FormControl className='w-100' variant="outlined" color={color} required={true}>
                                <InputLabel id='alertType' color={color}>Alert Type</InputLabel>
                                <Select labelId="alertType" onChange={onChange} name='alertType' value={data.alertType}
                                    label='Alert Type' defaultValue='COVID_19'
                                >
                                    {alertType.map(e => <MenuItem key={e.key} value={e.value}>{e.text}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    }

                    {
                        errored_locations.length ?
                            <Grid container item xs={12}>

                                <Grid item xs={12}>
                                    <h6>Local post failed for following locations</h6>
                                </Grid>
                                {
                                    errored_locations.map(e => {
                                        let l = locations.find(l => l.name === e.name);
                                        return ({ locationName: `${l?.locationName} ${l?.nickName ? `(${l.nickName})` : ''}`, error: e.error })
                                    }).map(e => (
                                        <React.Fragment>
                                            <Grid item xs={5} md={4} lg={3}>{e.locationName}</Grid>
                                            <Grid item xs={7} md={8} lg={9} className='text-danger'>{e.error}</Grid>
                                        </React.Fragment>
                                    ))
                                }

                            </Grid>
                            :
                            null
                    }

                    <Grid item xs={12} className='d-flex justify-content-end'>

                        <Button onClick={() => setSchedulePost(true)} color={color} type='submit' variant='contained'
                            size='large' className='mr-2' disabled={!!posting}>
                            {
                                posting === 'SCHEDULED' &&
                                <span className='mr-1'><CircularProgress size={15} /></span>
                            }
                            Schedule
                        </Button>
                        <Dialog open={openDTPicker} onClose={() => setOpenDTPicker(false)}>
                            <DialogTitle >
                                <div className='d-flex'>
                                    <h6 className='my-auto'>Schedule Post</h6>
                                    <IconButton size='small' className='ml-auto my-auto' onClick={() => setOpenDTPicker(false)}>
                                        <LineIconRegular name='close' size='xs' />
                                    </IconButton>
                                </div>
                            </DialogTitle>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker className='w-25 mr-2 my-auto' label="Post date and time" inputVariant="outlined" size='small'
                                    value={moment(data.postDate)}
                                    disablePast
                                    variant='static'
                                    onChange={(dt) => setData({ ...data, postDate: dt })} color={color}
                                />
                            </MuiPickersUtilsProvider>
                            <DialogActions>
                                <Button
                                    variant='contained' className='mx-auto'
                                    color='primary' onClick={onPublish}
                                >
                                    Schedule
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Button variant='contained' color='primary' ref={publishBtnRef}
                            onClick={(ev) => {
                                setSchedulePost(false)
                                setStatus('PUBLISH_NOW');
                                setAnchorEl(ev.currentTarget)
                            }}
                            type='submit' size='large' disabled={!!posting}>
                            {
                                posting === 'POST_NOW' &&
                                <span className='mr-1'><CircularProgress size={15} /></span>
                            }
                            Publish Now
                        </Button>
                        {
                            id
                                ?
                                <Button
                                    variant='contained' color='primary'
                                    onClick={(ev) => {
                                        setStatus('DELETE');
                                        setAnchorEl(ev.currentTarget);
                                    }}
                                    size='large' disabled={deleting} type='button' className='ml-2'
                                >
                                    {
                                        deleting &&
                                        <span className='mr-1'><CircularProgress size={15} /> </span>
                                    }
                                    Delete Post
                                </Button>
                                :
                                null
                        }
                        <Popper
                            open={!!anchorEl}
                            anchorEl={anchorEl}
                            placement='top'
                            onClose={() => setAnchorEl(null)}
                            modifiers={{
                                flip: {
                                    enabled: false,
                                },
                                preventOverflow: {
                                    enabled: true,
                                    boundariesElement: 'scrollParent',
                                }
                            }}
                        >
                            <Card className='border py-3 px-2 mb-2'>
                                <h6 className='text-center'>Are you sure {status === 'DELETE' ? 'you want to delete' : 'you want to publish'}?</h6>
                                <Button
                                    onClick={() => setAnchorEl(null)}
                                    variant='outlined' color='primary' size='small'
                                >
                                    {status === 'DELETE' ? 'Cancel' : 'Review'}
                                </Button>
                                <Button
                                    color='primary'
                                    onClick={() => status === 'DELETE' ? deleteScheduledPost() : onPublish()}
                                    variant='outlined' size='small' className='ml-2'
                                >
                                    {status === 'DELETE' ? 'Delete' : 'Publish'}
                                </Button>
                            </Card>
                        </Popper>
                    </Grid>
                </Grid>
            </form>
        </Paper >
    );

}