import React, { useEffect, useState } from 'react';
import {
	Grow, Typography, ListItem, List, ListItemAvatar, ListItemText, Avatar, AppBar,
	MenuItem, Drawer, Divider, Popper, Paper, Collapse, Tooltip, Zoom, IconButton
} from '@material-ui/core';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { useDispatch, useSelector } from 'react-redux';

import './styles.css';
import { useMixpanel } from 'react-mixpanel-browser';
import { logout } from '../../redux/action/action';
import { _fetch } from '../../netReq';
import { primaryColor } from '../../theme';
import LineIconRegular from '../../react-lineicons-regular';
import LineIconLight from '../../react-lineicons-light';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function Header() {
	const [path, setPath] = useState('');
	const [, setCookie, removeCookie] = useCookies(['account_id']);
	const red = useSelector(st => st.userReducer);
	const history = useHistory();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);
	const [openProfile, setOpenProfile] = useState(false);
	const [bigAvatar, setBigAvatar] = useState(-1);
	const mixpanel = useMixpanel();

	let query = useQuery();
	let location = useLocation();

	useEffect(() => {

		let listener = history.listen((location, action) => {
			if (action === 'PUSH' && location.pathname !== path) {
				mixpanel.track('page view', { path: location.pathname });
				setPath(location.pathname);
			}
		});

		if (query.get('s') && ['e', 's'].includes(query.get('s'))) {
			mixpanel.track('referrer', {
				type: query.get('s') === 'e' ? 'email' : query.get('s') === 's' ? 'sms' : 'undefined',
				path: location.pathname
			});
		}

		return () => listener();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [menuOpen, setMenuOpen] = useState(false);

	const toggleDrawer = () => {
		setMenuOpen(!menuOpen);
	};

	const navLinkClass = 'text-decoration-none mx-2 nav-header-link';

	const handlePopoverClose = () => {
		setAnchorEl(null);
		setOpenProfile(false)
		setBigAvatar(-1)
	};

	const handleOpenProfile = (event) => {
		setAnchorEl(event.currentTarget);
		setOpenProfile(true);
	};

	function handleAccountLogout() {
		console.log('logout');
		_fetch(`${process.env.REACT_APP_API_URL}/account/logout`)
			.then(res => {
				handlePopoverClose();
				dispatch(logout());
				removeCookie('account_id');
				history.push('/');
			})
			.catch(err => {
				console.log(err);
			});
	}

	function getClassName(route) {
		return `text-reset text-decoration-none my-auto mx-3 ${path === route ? 'nav-header-link-selected' : navLinkClass}`;
	}

	const menu = [{
		label: 'Notifications',
		link: '/notifications',
		icon: 'notification-alt-1'
	}, {
		label: 'Reviews',
		link: '/reviews',
		icon: 'star'
	}, {
		label: 'Questions',
		link: '/questions',
		icon: 'user-question'
	}, {
		label: 'Posts',
		link: '/scheduled',
		icon: 'page'
	}, {
		label: 'Add Listing',
		link: '/login',
		icon: 'box-add'
	}];

	const menu2 = [{
		label: 'Home',
		link: '/'
	}, {
		label: 'Features',
		link: '/#features'
	}, {
		label: 'FAQs',
		link: '/#faq'
	}, {
		label: 'Pricing',
		link: '/pricing'
	}, {
		label: 'Support',
		link: '/about'
	}, {
		label: 'Sign In',
		link: '/signin'
	}, {
		label: 'Sign Up',
		link: '/register'
	}];

	return (
		<React.Fragment>
			<AppBar onMouseLeave={handlePopoverClose}
				onMouseEnter={() => setBigAvatar(-2)}
				position='fixed' color='default'
				style={{ height: '60px' }}
			>

				{/* Menu for mobile devices */}
				<div className='d-flex align-items-center flex-row px-3 my-auto'>

					<Drawer anchor={'left'} open={menuOpen} onClose={toggleDrawer}>
						<div
							style={{ minWidth: 150 }}
							role="presentation"
							onClick={toggleDrawer}
							onKeyDown={toggleDrawer}
						>
							<List>
								{
									red?.account?.email
										?
										menu.map(({ label, link }) => (
											<ListItem button key={label} onClick={() => history.push(link)}>
												<ListItemText primary={label} />
											</ListItem>
										))
										:
										menu2.map(({ label, link }) => (
											<ListItem button key={label} onClick={() => history.push(link)}>
												<ListItemText primary={label} />
											</ListItem>
										))
								}


								{
									red.currentUser?.picture ?
										<React.Fragment>
											<Divider />
											<List>
												{
													red.users.map((e, i) => (
														<ListItem alignItems="flex-start"
															onClick={() => {
																setCookie('account_id', e?.account_id, { maxAge: 30 * 24 * 60 * 60 });
															}}
															button selected={e.account_id === red.currentUser.account_id}
														>
															<ListItemAvatar>
																<Avatar alt={e.firstName} src={e.picture} />
															</ListItemAvatar>
															<ListItemText
																primary={`${e.firstName} ${e.lastName}`}
																secondary={e.email}
															/>
														</ListItem>
													))
												}
											</List>
										</React.Fragment>
										:
										null
								}

								{
									red?.account?.email
										?
										<React.Fragment>
											<Divider />
											<ListItem button key='/profile' onClick={() => history.push('/profile')}>
												<ListItemText primary="Profile" />
											</ListItem>
											<ListItem button key={'/manage_listings'} onClick={() => history.push('/manage_listings')}>
												<ListItemText primary="Manage Listings" />
											</ListItem>
											<ListItem button key={'/buy_slots'} onClick={() => history.push('/buy_slots')}>
												<ListItemText primary="Buy Slots" />
											</ListItem>
											<ListItem button key={'/billing'} onClick={() => history.push('/billing')}>
												<ListItemText primary="Billing &amp; Subscription" />
											</ListItem>
											<ListItem button key={'Logout'} onClick={handleAccountLogout}>
												<ListItemText primary="Logout" />
											</ListItem>
										</React.Fragment>
										:
										null
								}

							</List>

							<Divider />


						</div>
					</Drawer>

					<LineIconLight name='menu-circle' size='lg' className='d-inline d-md-none mr-2 pointer' onClick={toggleDrawer} />

					<Link to='/' className='text-decoration-none d-flex pl-2 mr-4'>
						<Typography variant='h4' color='primary' style={{ fontWeight: 600 }} >JeeMB</Typography>
					</Link>

					{/* <div className='d-inline d-lg-none'></div> */}

					<div className='d-none d-md-flex align-items-center h-100 w-100 justify-content-end'>

						{
							red?.account?.name
								?
								<React.Fragment>

									{
										Array.isArray(red.locations) && red.locations.length > 0
											?
											menu.map(({ label, link, icon }, index) => (
												<Link className={getClassName(link)} to={link} key={index}>
													<LineIconLight size='sm' name={icon} className='mr-1' />
													{label}
												</Link>
											))
											:
											null
									}

									<div className='px-3 d-flex ml-auto align-items-center h-100 '
										onMouseEnter={() => setOpenProfile(false)}
										onMouseLeave={() => setBigAvatar(-2)}
									>
										{
											bigAvatar !== -1 ?
												<Grow in timeout={400}>
													<span className='d-flex h-100'>
														{
															red.users.map((e, i) => (
																<Tooltip key={i}
																	onMouseEnter={() => setBigAvatar(i)}
																	className='my-auto'
																	title={
																		<div>
																			<Typography variant='h5'>{e?.firstName} {e?.lastName}</Typography>
																			<Typography variant='body2'>{e?.email}</Typography>
																		</div>
																	}
																	TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }}
																>
																	<Avatar src={e?.picture}
																		style={{
																			// border: `3px solid ${primaryColor}`,
																			transition: 'height 0.1s, width 0.1s, margin 0.1s',
																			height: bigAvatar === i ? '45px' : '35px',
																			width: bigAvatar === i ? '45px' : '35px',
																			marginLeft: bigAvatar !== i ? -8 : 10,
																			marginRight: bigAvatar === i && i !== red.users.length - 1 && 18
																		}}
																		alt={e?.firstName}
																		onClick={() => {
																			setCookie('account_id', e?.account_id, { maxAge: 30 * 24 * 60 * 60 });
																		}}
																	/>

																</Tooltip>
															))
														}

													</span>
												</Grow>
												:
												red.currentUser?.picture &&
												<Avatar src={red.currentUser?.picture}
													style={{
														// border: `3px solid ${primaryColor}`,
														height: '35px',
														width: '35px',
													}}
													alt={red.currentUser?.firstName}
												/>
										}

									</div>
								</React.Fragment>
								:
								menu2.map(({ label, link }, index) => (
									<Link className={getClassName(link)} to={link} key={index}>
										{label}
									</Link>
								))
						}

						{
							red?.account?.name
								?
								<React.Fragment>
									<IconButton onMouseEnter={handleOpenProfile}>
										<LineIconRegular
											name='cog'
											style={{ color: primaryColor }}
											size='sm'
										/>
									</IconButton>
									<Popper
										className='mt-1'
										anchorEl={anchorEl}
										open={true}
										onClose={handlePopoverClose}
										disablePortal
									>
										<Collapse in={openProfile} timeout={150}>
											<Paper elevation={10} onMouseLeave={handlePopoverClose}>
												<MenuItem onClick={() => history.push('/profile')}>Profile</MenuItem>
												<MenuItem onClick={() => history.push('/manage_listings')}>Manage Listings</MenuItem>
												<MenuItem onClick={() => history.push('/buy_slots')}>Buy Slots</MenuItem>
												<MenuItem onClick={() => history.push('/billing')}>Billing &amp; Subscription</MenuItem>
												<MenuItem onClick={handleAccountLogout}>Logout</MenuItem>
											</Paper>
										</Collapse>
									</Popper>
								</React.Fragment>
								:
								null
						}

					</div>
				</div>
			</AppBar >

		</React.Fragment >
	)
}